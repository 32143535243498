<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เปลี่ยนรหัสผ่า่น</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="sendData">
           <div class="row">
              <label class="col-sm-2 col-form-label">รหัสผ่าน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|min:5'"
                  :error="getError('password')"
                  v-model="password"
                  name="password"
                  type="password"
                  placeholder="รหัสผ่าน"
                  addon-left-icon="tim-icons icon-lock-circle"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="submit" type="success" >บันทึก</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import { userService } from '@/services';

import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['AddUserStatus','user']),
  },
  data() {
    return {
      password:'',
      model: {
        ID: 0,
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        address: '',
        nationality: '',
        province: '',
        amphure: '',
        district: '',
        zipCode: '',
        idNumber: '',
        dimension_height: 0,
        dimension_width: 0,
      }
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['UpdatePassword','getUserByID']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();
      console.log(isValidForm)
      if (isValidForm) {
        const user = {
          "ID":  this.model.ID,
          "userName": this.model.userName,
          "password": this.password,
        }

        console.log(user)

        this.UpdatePassword(user)
      }
    }
  },
  mounted() {
    // Fuse search initialization.
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    this.getUserByID(this.$route.params.id)
  },

  watch: {
    user(val){
      this.model = val
    },
    AddUserStatus(val){
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/user/list')
        })
      }  else {
         swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
      //this.clearStateStatus()
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
