<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Receive Edit</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
          <h4 class="card-title">Form Receive Item</h4>
        </template>
        <div>
          <form method="get" action="/" class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">PO</label>
              <div class="col-sm-10">
                <base-input placeholder="PO" v-model="Model.po" disabled></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Barcode</label>
              <div class="col-sm-10">
                <base-input placeholder="Barcode" v-model="Model.barcode"></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Product Name</label>
              <div class="col-sm-10">
                <base-input placeholder="Product Name" v-model="Model.productName"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">ADD</base-button>
              </div>
            </div>

          </form>
        </div>
      </card>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Receive Item List</h4>
          <div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="การกระทำ">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import receiveList from './receive'
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import router from "@/routes/router";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      Model:{
        po:'PO000001',
        barcode:'',
        productName:'',
        id:'',
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['po', 'lot', 'barcode'],
      tableColumns: [
        {
          prop: 'po',
          label: 'PO',
          minWidth: 100
        },
        {
          prop: 'barcode',
          label: 'Barcode',
          minWidth: 100
        },
        {
          prop: 'productName',
          label: 'Product Name',
          minWidth: 200
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.barcode}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    AddItem(){
     this.tableData.push({
       id: this.total+1,
       po: this.Model.po,
       barcode: this.Model.barcode,
       lot: '2020100100003',
       productName: this.Model.productName
     });
     this.Model.barcode="";
     this.Model.productName="";
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
