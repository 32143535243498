<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มรายการใบสั่งซื้อ</h2>
    </div>

    <div class="row mt-5">
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูลผู้ส่ง</h4>
            </template>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-3 col-form-label">รหัสลูกค้า</label>
                <div class="col-sm-9">
                  <base-input placeholder="CUS-xxx" v-model="Sender.CustomerNo" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">เบอร์ติดต่อ</label>
                <div class="col-sm-9">
                  <base-input placeholder="090000000x" type="number" v-model="Sender.CustomerMobile"
                              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              maxlength="10"></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">ที่อยู่</label>
                <div class="col-sm-9">
                  <base-input placeholder="address" v-model="Sender.CustomerAddress"></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
                <div class="col-sm-9">
                  <base-input placeholder="address" v-model="Sender.CustomerZipcode"></base-input>
                </div>
              </div>
            </form>
          </card>
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูลผู้รับ</h4>
            </template>
            <div class="col-md-12">
              <form method="get" action="/" class="form-horizontal">
                <div class="row">
                  <label class="col-sm-3 col-form-label">ชื่อ-นามสกุล</label>
                  <div class="col-sm-9">
                    <base-input placeholder="name" v-model="Receiver.Name"></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">เบอร์ติดต่อ</label>
                  <div class="col-sm-9">
                    <base-input placeholder="090000000x" type="number" v-model="Receiver.Mobile"
                                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="10"></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">ที่อยู่</label>
                  <div class="col-sm-9 mb-2">
                      <base-textarea placeholder="address" v-model="Receiver.Address"/>
                      <div style="font-size: 10px;color:red"> * ข้อมูลที่อยู่ไม่ต้องใส่รหัสไปรษณีย์</div>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
                  <div class="col-sm-9">
                    <base-input placeholder="รหัสไปรษณีย์" v-model="Receiver.Zipcode"></base-input>
                    <!-- <el-select
                      class="select-primary"
                      placeholder="---- กรุณาเลือก ----"
                      @input="dispatch"
                      size="large"
                      :filterable="true"
                      v-model="Receiver.Zipcode">
                      <el-option
                        class="select-primary"
                        v-for="data in zipcodeList"
                        :key="data.ID"
                        :label="data.Value"
                        :value="data.Value"
                      >
                      </el-option>
                    </el-select> -->

                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">ยอดเก็บเงินปลายทาง</label>
                  <div class="col-sm-9">
                    <base-input placeholder="0" v-model="Receiver.COD"></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">รูปแบบการส่ง</label>
                  <div class="col-sm-9 mb-2">
                    <el-select
                      class="select-primary"
                      placeholder="---- กรุณาเลือก ----"
                      @input="dispatch"
                      size="large"
                      :filterable="true"
                      v-model="Receiver.SendType">
                      <el-option
                        class="select-primary"
                        v-for="data in SendType"
                        :key="data.ID"
                        :label="data.Name"
                        :value="data.Name"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">REF 1</label>
                  <div class="col-sm-9">
                    <base-input  v-model="Receiver.Ref1"></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">REF 2</label>
                  <div class="col-sm-9">
                    <base-input v-model="Receiver.Ref2"></base-input>
                  </div>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">สินค้า</h4>
            </template>
            <div>
              <form method="get" action="/" class="form-horizontal">
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label">สินค้า</label>
                  <div class="col-sm-9">
                    <el-select
                      class="select-primary"
                      @input="dispatch"
                      size="large"
                      :filterable="true"
                      placeholder="---- กรุณาเลือก ----"
                      v-model="ItemSelect"
                      @change="setItemSelect"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in stockItems"
                        :key="item.ID"
                        :label="item.SKU +' -'+item.ProductName+' คงเหลือ : '+item.Amount"
                        :value="item.ID"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">จำนวน</label>
                  <div class="col-sm-9">
                    <base-input placeholder="0" v-model="Amount" v-on:change="numChange" type="number"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">
                      <i class="tim-icons icon-simple-add"></i>
                      เพิ่มสินค้า
                    </base-button>
                  </div>
                </div>
              </form>
            </div>
            <div class="mt-5">
              <h4 slot="header" class="card-title">รายการสินค้า2</h4>
              <div class="col-md-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select
                  class="select-primary mb-1 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>


              <el-table :data="queriedData">

               <el-table-column label="รูปภาพ" :min-width="200">
                <div slot-scope="props" >

                   <img class="card-img" :src="props.row.Image"  onerror="javascript:this.src='img/image-not-found.png'"/>
                </div>
              </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="135" align="right" label="การกระทำ">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>

          </card>


        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 center" style="text-align: center;">
          <base-button class="mt-auto" native-type="button" type="success" v-on:click="SaveOrder">บันทึก
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import zipcode from '@/helpers/zipcode.json'
import BaseTextarea from "@/components/Inputs/BaseTexarea";

export default {
  components: {
    BaseTextarea,
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users', ['user', 'status', 'itemData', 'statusItem', 'stockData']),
    ...mapState('orders', ['orderStatus', 'zipList']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: false,
      CustomerID: "",
      customerFullName: "",
      LastRunning: 1,
      ItemSelect: "",
      zipcodeList: zipcode,
      TotalNum: 0,
      Amount: "",
      preAddData: {},
      stockItems: {},
      SendType: [
        {ID: 1, Name: "ไปรษณีย์ EMS"},
        {ID: 2, Name: "ไปรษณีย์ ลงทะเบียน"},
        {ID: 3, Name: "ไปรษณีย์ COD"},
        {ID: 4, Name: "Kerry"},
        {ID: 5, Name: "Kerry COD"},
        {ID: 6, Name: "Kerry Same Day"},
        {ID: 8, Name: "Flash Express"},
        {ID: 13, Name: "Flash COD"},
        {ID: 7, Name: "แมสเซนเจอร์"},
        {ID: 9, Name: "Lazada"},
        {ID: 10, Name: "Shopee"},
        {ID: 11, Name: "SCG"},
        {ID: 12, Name: "J&T"},
        {ID: 14, Name: "Best Exress"},
        {ID: 15, Name: "ORANGE EXPRESS"},
        {ID: 16, Name: "NRF Go to laos"},
        {ID: 17, Name: "Tiktok"},
        {ID: 18, Name: "J&T COD"},
        {ID: 19, Name: "Kerry Central"},
      ],
      Receiver: {
        Name: "",
        Mobile: "",
        Address: "",
        Zipcode: "",
        COD: "",
        SendType: "",
        Ref1:"",
        Ref2:"",
      },
      Sender: {
        CustomerNo: '',
        CustomerMobile: '',
        CustomerAddress: "",
        CustomerZipcode: "",
        productName: '',
        price: 0,
        amount: 0,
        expireDate: ""
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      tableColumns: [
        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 200
        },
        {
          prop: 'amount',
          label: 'จำนวน',
          minWidth: 100
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }

    this.getStock()
    this.getZipCode()
    this.Me()
  },
  methods: {
     ...mapActions('account', ['logout']),
    ...mapActions('users', ['Me', 'getMeStock', 'getUserByCode', 'getItemByCode', 'getLastItemCode', 'getItemCustomerByCode']),
    ...mapActions('orders', ['SaveOrderData', 'clearStateStatus' , 'getZipCode']),
    filter(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false
        }, 200)
      } else {
        this.clients = []
      }
    },
    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    setItemSelect(data) {
      this.preAddData = this.stockItems.filter(elem => {
        if (elem.ID === data) return elem;
      })
    },
    getStock() {
      this.getMeStock()
    },
    numChange() {

    },
    SaveOrder() {

      if (this.Receiver.Name === '' || this.Receiver.Name === undefined || this.Receiver.Name === null) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกชื่อ-นามสกุลผู้รับ`,
          type: 'error',
        });
        return
      }

      if (this.Receiver.Mobile === '' || this.Receiver.Mobile === undefined) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกเบอร์ติดต่อผู้รับ`,
          type: 'error',
        });
        return
      }

      if (this.Receiver.Address === '' || this.Receiver.Address === undefined) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกที่อยู่ผู้รับ`,
          type: 'error',
        });
        return
      }

      if (this.Receiver.Zipcode === '' || this.Receiver.Zipcode === undefined) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกรหัสไปรษณีย์ผู้รับ`,
          type: 'error',
        });
        return
      }

      if (this.Receiver.SendType === '' || this.Receiver.SendType === undefined || this.Receiver.SendType == null) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกรูปแบบการส่งผู้รับ`,
          type: 'error',
        });
        return
      }


      if (this.tableData.length === 0) {
        swal({
          title: 'ไม่สามารถบันทึกข้อมูลได้',
          text: `กรุณากรอกรายการสินค้า`,
          type: 'error',
        });
        return
      }
      const data = {
        sender: this.Sender,
        receiver: this.Receiver,
        data: this.tableData
      }
      this.SaveOrderData({data})
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.barcode}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    AddItem() {
      if (!this.Amount) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `กรุณากรอกจำนวนสินค้า`,
          type: 'error',
        });
        return;
      }
       if (this.Amount <= 0) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `จำนวนสินค้าที่กรอกต้องมากกว่า 0`,
          type: 'error',
        });
        return;
      }
      if (this.Amount === 0) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `จำนวนสินค้าที่กรอกเป็น 0`,
          type: 'error',
        });
        return;
      }


      console.log(this.preAddData[0].Amount);



      if (this.Amount > this.preAddData[0].Amount) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `จำนวนสินค้าที่กรอกมากกว่าจำนวนสินค้าคงเหลือ`,
          type: 'error',
        });
        return;
      }

      let num = 0;
      let check = false;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.preAddData[0].SKU === this.tableData[i].barcode) {
          num = num + this.tableData[i].amount;
          check = true;
        }
      }

       if (check === true) {
           swal({
            title: 'ไม่สามารถเพิ่มสินค้าได้',
            text: `มีสินค้าที่เลือกแล้ว`,
            type: 'error',
          });
          return;
       }


      // if (check === true) {
      //   let total = this.preAddData[0].Amount - num;
      //   if (this.Amount > total) {
      //     swal({
      //       title: 'ไม่สามารถเพิ่มสินค้าได้',
      //       text: `จำนวนสินค้าที่กรอกมี่ไม่เพียงพอกับจำนวนสินค้าที่เหลือ`,
      //       type: 'error',
      //     });
      //     return;
      //   }
      // }
      this.tableData.push({
        Image: this.preAddData[0].Image,
        barcode: this.preAddData[0].SKU,
        ProductName: this.preAddData[0].ProductName,
        amount: this.Amount
      });


      this.preAddData = {}
      this.ItemSelect = ""
      this.Amount = 0
    },
    deleteRow(row) {

     console.log(row)


      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.barcode === row.barcode
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    orderStatus(val) {
      if (val.message === "success") {
        // swal({
        //   title: `บันทึกข้อมูลสำเร็จ!`,
        //   text: 'บันทึกข้อมูลสำเร็จ!',
        //   buttonsStyling: false,
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   type: 'success'
        // }).then(_ => {
        //   this.$router.push('/order/list')
        // })
        this.$router.push('/order/list')
      }
      this.clearStateStatus()
    },
    status(val) {
      if (val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }
    },
    user(val) {
      this.CustomerID = val.ID
      const zeroPad = (num) => String(num).padStart(3, '0')
      this.Sender.CustomerNo = 'C' + zeroPad(val.ID)
      this.Sender.CustomerAddress = val.address + ' แขวง/ต. ' + val.district  +  ' เขต/อ. ' + val.amphure + ' จ.' + val.province
      this.Sender.CustomerMobile = val.phoneNumber
      this.Sender.CustomerZipcode = val.zipCode
    },
    itemData(val) {
      const zeroPad = (num) => String(num).padStart(4, '0')
      this.BarCodePrefix = val.code
      this.BarCode = val.code + zeroPad(val.no)
      this.LastRunning = val.no
    },
    stockData(val) {
      this.stockItems = val.items
    },
    zipList(val) {
      // this.zipcodeList = val.items.data;
      this.zipcodeList = [];
      for (let i = 0; i < val.items.data.length; i++) {
        this.zipcodeList.push({ID: val.items.data[i] , Value: val.items.data[i]})
      }

    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.0
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.el-select {
  width: 100%;
}

</style>
