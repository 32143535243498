<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มสมาชิก</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="sendData">
            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อผู้ใช้งาน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('username')"
                  v-model="model.userName"
                  name="username"
                  v-on:keypress="isLetter($event)"
                  placeholder="ชื่อผู้ใช้งาน"
                  addon-left-icon="tim-icons icon-email-85"
                />
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">รหัสผ่าน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|min:5'"
                  :error="getError('password')"
                  v-model="model.password"
                  name="password"
                  type="password"
                  placeholder="รหัสผ่าน"
                  addon-left-icon="tim-icons icon-lock-circle"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('First Name')"
                  v-model="model.firstName"
                  name="First Name"
                  placeholder="ชื่อ"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">นามสกุล</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Last Name')"
                  v-model="model.lastName"
                  name="Last Name"
                  placeholder="นามสกุล"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">อีเมล์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|email'"
                  :error="getError('email')"
                  v-model="model.email"
                  name="email"
                  placeholder="อีเมล์"
                  addon-left-icon="tim-icons icon-email-85"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Phone Number')"
                  v-model="model.phoneNumber"
                  name="phoneNumber"
                  placeholder="เบอร์โทรศัพท์"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ขนาดความสูง</label>
              <div class="col-sm-10">
                <base-input
                  type="number"
                  v-validate="'required'"
                  :error="getError('dimension height')"
                  v-model="model.dimension_height"
                  name="dimension height"
                  placeholder="ขนาดความสูง"
                  addon-left-icon="tim-icons icon-app"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ขนาดความกว้าง</label>
              <div class="col-sm-10">
                <base-input
                  type="number"
                  v-validate="'required'"
                  :error="getError('dimension width')"
                  v-model="model.dimension_width"
                  name="dimension width"
                  placeholder="ขนาดความกว้าง"
                  addon-left-icon="tim-icons icon-app"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ที่อยู่</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('ที่อยู่')"
                  v-model="model.address"
                  name="ที่อยู่"
                  placeholder="ที่อยู่"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ตำบล</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('ตำบล')"
                  v-model="model.district"
                  name="ตำบล"
                  placeholder="ตำบล"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">อำเภอ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('อำเภอ')"
                  v-model="model.amphure"
                  name="อำเภอ"
                  placeholder="อำเภอ"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">จังหวัด</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('จังหวัด')"
                  v-model="model.province"
                  name="จังหวัด"
                  placeholder="จังหวัด"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">รหัสไปรษณีย์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('รหัสไปรษณีย์')"
                  v-model="model.zipcode"
                  name="รหัสไปรษณีษ์"
                  placeholder="รหัสไปรษณีษ์"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>

            </div>

             <div class="row">
              <label class="col-sm-2 col-form-label">ชั้นวาง</label>
                <div class="col-sm-10">
                    <el-select
                        class="select-primary"
                        @input="dispatch"
                        size="large"
                        :filterable="true"
                        placeholder="---- กรุณาเลือก ----"
                        v-model="ItemSelect"
                        @change="setItemSelect"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in stockItems"
                          :key="item.location_id"
                          :label="item.location_id"
                          :value="item.location_id"
                        >
                        </el-option>
                      </el-select>
                  
                </div>
             </div>

               <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">
                      <i class="tim-icons icon-simple-add"></i>
                      เพิ่มชั้นวาง
                    </base-button>
                  </div>
                </div>

            <div class="mt-2">
             
              <div class="col-md-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select
                  class="select-primary mb-1 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>


              <el-table :data="queriedData">

    
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="135" align="right" label="การกระทำ">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>




            <div class="row" >
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="submit" type="success" >บันทึก</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['AddUserStatus' , 'stockData']),
     queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      stockItems: {},
      ItemSelect: "",
      model: {
        userName: '',
        password: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        address: '',
        nationality: '',
        province: '',
        amphure: '',
        district: '',
        zipcode: '',
        idNumber: '',
        AcceptTerm: false,
        dimension_height: 0.00,
        dimension_width: 0.00,
        shelf: []
      },
       pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      tableColumns: [
        {
          prop: 'location_id',
          label: 'ชั้นวาง',
          minWidth: 200
        },
       
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['AddUser' , 'AddUser2' , 'getMeStock' , 'getlocations']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();
      this.model.dimension_height = parseFloat(this.model.dimension_height).toFixed(2)
      this.model.dimension_width = parseFloat(this.model.dimension_width).toFixed(2)
      if (isValidForm) {
       

        var list = []

        for (let i = 0; i < this.tableData.length; i++) {
           list.push(this.tableData[i].location_id)
        }

        this.model.shelf = list

        const user = this.model
               
        this.AddUser2({user})
      }
    },
    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    setItemSelect(data) {
      this.preAddData = this.stockItems.filter(elem => {
        if (elem.location_id === data) return elem;
      })
    },
    getStock() {
      this.getlocations()
    },
    AddItem() {

     for (let i = 0; i < this.tableData.length; i++) {
      if(this.tableData[i].location_id == this.preAddData[0].location_id) {
         this.ItemSelect = ""
        return;
      }
     }
      
      
      this.tableData.push({
        location_id: this.preAddData[0].location_id,
      });

       this.ItemSelect = ""

      
    },
     handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.location_id}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {

     console.log(row)


      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.location_id === row.location_id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
  },
  created () {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

     this.getStock()

  },
  watch: {
    stockData(val) {
      console.log(val.data)
      this.stockItems = val.data
    },
    AddUserStatus(val){
      console.log(val);
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/user/list')
        })
      } else {
         swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
      
      this.clearStateStatus()
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.el-select {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
</style>

