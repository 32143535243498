<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">สมาชิก</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
           <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="mb-3">
                <router-link to="/user/create" >
                  <base-button type="success" class="animation-on-hover">เพิ่มสมาชิก</base-button>&nbsp;
                </router-link>
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
             <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>

            </div>

            <el-table :data="queriedData">

            <el-table-column :min-width="205" align="left" label="การกระทำ">
                <div slot-scope="props">
                 <el-tooltip
                 v-if="props.row.status == 'approved'"
                    content="เติมเงิน"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                    <base-button
                    v-if="props.row.status == 'approved'"
                      @click.native="handleTopUp(props.$index, props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-coins"></i>
                    </base-button>
                 </el-tooltip>


                  <el-tooltip
                  v-if="props.row.status == 'approved'"
                    content="พิมพ์บาร์โค้ด"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                    <base-button
                    v-if="props.row.status == 'approved'"
                      @click.native="handlePrint(props.$index, props.row)"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-paper"></i>
                    </base-button>
                  </el-tooltip>


                  <el-tooltip
                  v-if="props.row.status == 'approved'"
                    content="แก้ไขรายละเอียด"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                   <base-button
                   v-if="props.row.status == 'approved'"
                    @click.native="editUser(props.$index, props.row)"
                    class="like btn-link"
                    type="warning"
                    size="sm"
                    icon>
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  </el-tooltip>


                   <el-tooltip
                   v-if="props.row.status == 'approved'"
                    content="ลบข้อมูล"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                      <base-button
                      v-if="props.row.status == 'approved'"
                        @click.native="handleDelete(props.$index, props.row)"
                        class="like btn-link"
                        type="danger"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                   </el-tooltip>



                  <el-tooltip
                   v-if="props.row.status == 'approved'"
                    content="เปลี่ยนรหัสผ่าน"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                      <base-button
                      v-if="props.row.status == 'approved'"
                        @click.native="changePassword(props.$index, props.row)"
                        class="like btn-link"
                        type="success"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                   </el-tooltip>
<!--                  <base-button-->
<!--                    @click.native="handleDelete(props.$index, props.row)"-->
<!--                    class="remove btn-link"-->
<!--                    type="danger"-->
<!--                    size="sm"-->
<!--                    icon-->
<!--                  >-->
<!--                    <i class="tim-icons icon-simple-remove"></i>-->
<!--                  </base-button>-->
                </div>
              </el-table-column>


              <el-table-column :min-width="150" align="left" label="รหัสลูกค้า" prop="cus_id" >
                <template slot-scope="props">
                  <div>{{props.row.cus_id}}</div>
                </template>
              </el-table-column>


             <el-table-column :min-width="150" align="left" label="ชั้นวาง" prop="shelf" >
                <template slot-scope="props">
                    <div>{{props.row.shelf_str}}</div>
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>


              <el-table-column label="ยอดเงินคงเหลือ" :min-width="125">
                <div slot-scope="props">
                  <div>{{props.row.balance}}</div>
                </div>
              </el-table-column>



              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div v-if="props.row.status == 'approved'" class="badge badge-success">{{props.row.status}}</div>
                  <div v-if="props.row.status == 'deleted'" class="badge badge-danger">{{props.row.status}}</div>

                </div>
              </el-table-column>




            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        <modal :show.sync="modalTopUp" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">เติมเงินให้ลูกค้า</h4>
          <div>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-2 col-form-label">รหัสลูกค้า</label>
                <div class="col-sm-10">
                  <base-input placeholder="None" v-model="Modal.CustomerCode" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">ชื่อลูกค้า</label>
                <div class="col-sm-10">
                  <base-input placeholder="None" v-model="Modal.CustomerName" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">จำนวนเงิน</label>
                <div class="col-sm-10">
                  <base-input type="number" placeholder="None" v-model="Modal.TopUpMoney"></base-input>
                </div>
              </div>
            </form>
            <div slot="footer">
              <base-button class="mt-3 ml-3 mb-3" v-on:click="TopupMoney">ตกลง</base-button>
              <base-button
                class="mt-3 mr-3 mb-3"
                type="danger"
                v-on:click="modalTopUp = false"
              >ปิด
              </base-button>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import {BasePagination, Modal} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import router from "@/routes/router";
import { userService } from '@/services';
import {mapActions, mapState} from "vuex";
import VueBarcode from 'vue-barcode';

export default {
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueBarcode,
  },
  computed: {
    ...mapState('users', ['barcode','TopUpStatus','userData']),
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      barcodeValue:"",
      modalTopUp: false,
      Modal:{
        CustomerId:"",
        CustomerCode:"",
        CustomerName:"",
        TopUpMoney:0,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['cus_id' , 'userName'  ,'firstName' , 'lastName' , 'email' , 'phoneNumber'],
      tableColumns: [
       
        {
          prop: 'userName',
          label: 'ชื่อผู้ใช้งาน',
          minWidth: 160
        },

        {
          prop: 'firstName',
          label: 'ชื่อ',
          minWidth: 150
        },
        {
          prop: 'lastName',
          label: 'นามสกุล',
          minWidth: 160
        },
        {
          prop: 'email',
          label: 'อีเมล์',
          minWidth: 160
        },
         {
          prop: 'phoneNumber',
          label: 'เบอร์โทรศัพท์',
          minWidth: 160
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['getUserBarcode','TopUpMoney','getAll' , 'getcustomers']),
    handlePrint(index,row) {
      this.getUserBarcode(row.ID)
    },
    editUser(index,row) {
      this.$router.push('/user/edit/'+row.ID)
    },
    changePassword(index,row) {
      this.$router.push('/user/changePassword/'+row.ID)
    },



    print(){
      this.$htmlToPaper('barcode')
    },
    handleTopUp(index,row) {
      this.Modal.CustomerCode = row.cus_id
      this.Modal.CustomerName = row.firstName
      this.modalTopUp = true
    },
    TopupMoney(){
      let data = {
        customer_id : this.Modal.CustomerCode,
        amt : this.Modal.TopUpMoney
      }
      this.TopUpMoney(data)
      this.Modal.CustomerCode =""
      this.Modal.CustomerName=""
      this.Modal.TopUpMoney = 0
      this.modalTopUp = false
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
      router.push('/receive/edit/'+row.id)
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.userName}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        //TODO :: Move to State Watcher

        console.log(row)

         const data =  {
          "ID": row.ID,
          "userName": row.userName
        }

         userService.deleteUser(data).then(_ =>{
           this.tableData.splice(indexToDelete, 1);
           this.getUser();
        })
      }
    },
    getUser(){
       this.getcustomers()
    },
  },
  created () {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }
    this.getcustomers()
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['cus_id' , 'userName'  ,'firstName' , 'lastName' , 'email' , 'phoneNumber'],
      threshold: 0.3
    });
  },
  watch: {
    userData(val){
      this.tableData = val.items.items
    },
    TopUpStatus(val){
      if (val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล สินค้า!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }else{
        swal({
          title: `เติมเงินสำเร็จ!`,
          text: '',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'info'
        })
      }
    },
    barcode(val){
      this.$router.push({name:'User Barcode',params:{barcode:val.code}})
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
