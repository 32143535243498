<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการใบสั่งซื้อ</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">

        <file-upload @change="onImageChange" select-text="เลือกไฟล์" />

        <card card-body-classes="table-full-width">
          <div class="row">

            <label class="col-sm-1 col-form-label" style="text-align: right">วันที่</label>
            <div class="col-sm-2 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Day">
                <el-option class="select-primary" v-for="data in DayType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>


            <label class="col-sm-1 col-form-label" style="text-align: right">เดือน</label>
            <div class="col-sm-3 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Mount">
                <el-option class="select-primary" v-for="data in MountType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>
            <label class="col-sm-1 col-form-label" style="text-align: right">ปี</label>
            <div class="col-sm-2 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Year">
                <el-option class="select-primary" v-for="data in YearType" :key="data.ID" :label="data.Name"
                  :value="data.Name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-2 mb-2">
              <base-button class="mt-auto" native-type="button" type="success" v-on:click="onSearch">ค้นหา</base-button>
            </div>
          </div>
        </card>


        <card card-body-classes="table-full-width">

          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">
                <router-link to="/order/create">
                  <base-button v-if="check" type="success"
                    class="animation-on-hover">สั่งบรรจุสินค้า</base-button>&nbsp;
                </router-link>

              </div>


              <div class="mb-6">
              </div>



              <div class=" mb-3">
                <base-button class="mt-auto" native-type="button" type="success" style="float: right;"
                  v-on:click="onExport">Export</base-button>
              </div>






            </div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-3">
                <el-select class="select-primary mb-3" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-3">
              </div>

              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search" placeholder="ชื่อผู้รับ"
                    v-model="searchQueryName" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>

              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search"
                    placeholder="เลขรายการสั่งซื้อ" v-model="searchQuery" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>


            </div>

            <el-table :data="queriedData">
              <el-table-column :min-width="135" align="left" label="การกระทำ">
                <div slot-scope="props">

                  <el-tooltip content="ดูรายละเอียด" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleEdit(props.$index, props.row)" class="edit btn-link" type="info"
                      size="sm" icon>
                      <i class="tim-icons icon-zoom-split"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="ลบ Order" effect="dark" :open-delay="300" placement="top">
                    <base-button v-if="props.row.status === 'NEW'" @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link" type="danger" size="sm" icon>
                      <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>


              <el-table-column label="วันที่" :min-width="180">
                <div slot-scope="props">
                  <div>{{ props.row.date_order }}</div>
                </div>
              </el-table-column>



              <el-table-column :min-width="160" align="left" label="เลขรายการสั่งซื้อ" prop="orderNo">
                <template slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleEdit(props.$index, props.row)">
                    {{(props.row.orderNo)}}
                  </div>

                </template>
              </el-table-column>






              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>


              <el-table-column :min-width="150" align="center" label="รูปแบบการส่ง">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>



                </div>
              </el-table-column>


              <!--<el-table-column label="Tracking Number" :min-width="200">
                <div slot-scope="props">
                  <div>{{props.row.tracking_number}}</div>
                </div>
              </el-table-column>-->




              <el-table-column label="สถานะ" :min-width="150">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status === 'NEW'">NEW</div>
                  <div class="badge badge-info" v-if="props.row.status === 'PICKING'">PICKING</div>
                  <div class="badge badge-success" v-if="props.row.status === 'COMPLETED'">COMPLETED</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'TRACKED'">TRACKED</div>
                  <div v-if="props.row.status === 'TRACKED'">{{ props.row.tracking_number }}</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'PACKED'">PACKED</div>
                  <div class="badge badge-danger" v-if="props.row.status === 'CANCEL'">CANCEL</div>

                  <div v-if="props.row.send_type !== 'แมสเซนเจอร์' && props.row.status === 'TRACKED'">
                    <base-button class="mt-auto" native-type="button" type="success"
                      v-on:click="onLink(props.row.send_type)">Link
                    </base-button>
                  </div>




                </div>
              </el-table-column>

              <el-table-column label="สร้างจาก" :min-width="300">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'Lazada'">
                    <div>Lazada</div>
                    <div>หมายเลขคำสั่งซื้อ : {{ randomNumber() }}</div>
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <div>Shopee</div>
                    <div>หมายเลขคำสั่งซื้อ : {{ randomNumber() }} </div>
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <div>Tiktok</div>
                    <div>หมายเลขคำสั่งซื้อ : {{ randomNumber() }}</div>
                  </div>
                  <div
                    v-if="props.row.send_type != 'Lazada' && props.row.send_type != 'Shopee' && props.row.send_type != 'Tiktok'">
                    <div>NRICH</div>
                  </div>
                </div>
              </el-table-column>


            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import orderList from './orders'
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import moment from 'moment'
import {
  ImageUpload,
} from 'src/components/index';
import {
  FileUpload,
} from 'src/components/index';


export default {
  components: {
    FileUpload,
    ImageUpload,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderList', 'orderHead', 'uploadTrackList']),
    ...mapState('users', ['transaction5', 'stockData', 'transaction', 'transaction6']),

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length) {
        result = this.searchedData;
        console.log(result)
      }
      if (this.searchedDataName.length) {
        result = this.searchedDataName;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      check: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      searchQueryName: '',
      propsToSearch: ['orderNo'],
      propsToSearchName: ['receiver_name'],
      tableColumns: [

        {
          prop: 'receiver_name',
          label: 'ขื่อผู้รับ',
          minWidth: 200
        },


      ],
      tableData: [],
      searchedData: [],
      searchedDataName: [],
      fuseSearch: null,
      fuseSearchName: null,
      MountType: [
        { ID: 1, Name: "มกราคม" },
        { ID: 2, Name: "กุมภาพันธ์" },
        { ID: 3, Name: "มีนาคม" },
        { ID: 4, Name: "เมษายน" },
        { ID: 5, Name: "พฤษภาคม" },
        { ID: 6, Name: "มิถุนายน" },
        { ID: 7, Name: "กรกฎาคม" },
        { ID: 8, Name: "สิงหาคม" },
        { ID: 9, Name: "กันยายน" },
        { ID: 10, Name: "ตุลาคม" },
        { ID: 11, Name: "พฤศจิกายน" },
        { ID: 12, Name: "ธันวาคม" },
      ],
      YearType: [
        { ID: 2021, Name: "2021" },
        { ID: 2022, Name: "2022" },
        { ID: 2023, Name: "2023" },
        { ID: 2024, Name: "2024" },
        { ID: 2025, Name: "2025" },
      ],
      DayType: [
        { ID: 0, Name: "ทั้งหมด" },
        { ID: 1, Name: "1" },
        { ID: 2, Name: "2" },
        { ID: 3, Name: "3" },
        { ID: 4, Name: "4" },
        { ID: 5, Name: "5" },
        { ID: 6, Name: "6" },
        { ID: 7, Name: "7" },
        { ID: 8, Name: "8" },
        { ID: 9, Name: "9" },
        { ID: 10, Name: "10" },
        { ID: 11, Name: "11" },
        { ID: 12, Name: "12" },
        { ID: 13, Name: "13" },
        { ID: 14, Name: "14" },
        { ID: 15, Name: "15" },
        { ID: 16, Name: "16" },
        { ID: 17, Name: "17" },
        { ID: 18, Name: "18" },
        { ID: 19, Name: "19" },
        { ID: 20, Name: "20" },
        { ID: 21, Name: "21" },
        { ID: 22, Name: "22" },
        { ID: 23, Name: "23" },
        { ID: 24, Name: "24" },
        { ID: 25, Name: "25" },
        { ID: 26, Name: "26" },
        { ID: 27, Name: "27" },
        { ID: 28, Name: "28" },
        { ID: 29, Name: "29" },
        { ID: 30, Name: "30" },
        { ID: 31, Name: "31" },
      ],
      Mount: null,
      Year: 2024,
      Day: null,
      UserID: ''
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['getAll', 'deleteOrder', 'uploadFileOrder']),
    ...mapActions('users', ["getWallets", 'getReportMonthlyOrderExport']),

    randomNumber: function () {
      var num = Math.floor(Math.random() * (1000000000000000 - 1));

      if (!isNaN(num) && num.length === 1) {
        num = '00000000000000' + num;
      } else if (!isNaN(num) && num.length === 2) {
        num = '0000000000000' + num;
      } else if (!isNaN(num) && num.length === 3) {
        num = '000000000000' + num;
      } else if (!isNaN(num) && num.length === 4) {
        num = '00000000000' + num;
      } else if (!isNaN(num) && num.length === 5) {
        num = '0000000000' + num;
      } else if (!isNaN(num) && num.length === 6) {
        num = '000000000' + num;
      } else if (!isNaN(num) && num.length === 7) {
        num = '00000000' + num;
      } else if (!isNaN(num) && num.length === 8) {
        num = '0000000' + num;
      } else if (!isNaN(num) && num.length === 9) {
        num = '000000' + num;
      } else if (!isNaN(num) && num.length === 10) {
        num = '00000' + num;
      } else if (!isNaN(num) && num.length === 11) {
        num = '0000' + num;
      } else if (!isNaN(num) && num.length === 12) {
        num = '000' + num;
      } else if (!isNaN(num) && num.length === 13) {
        num = '00' + num;
      } else if (!isNaN(num) && num.length === 14) {
        num = '0' + num
      } else if (!isNaN(num) && num.length === 15) {
        num = num
      }

      return num
    },


    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
      }
    },
    onImageChange(file) {
      console.log(file)
      let user = JSON.parse(localStorage.getItem('users'));
      console.log(localStorage.getItem('userID'))
      console.log("AAAA")

      this.uploadFileOrder(file)
      // var reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onload = ()=> {
      //   this.imageBase64 = reader.result;
      //   console.log(this.imageBase64)
      // }
    },
    onExport() {

      var day = ""
      if (this.Day == 0) {
        day = ""
      } else {
        day = this.Day
      }



      const data = {
        Day: day,
        Mount: this.Mount,
        Year: this.Year,
        UserID: localStorage.getItem('userID')
      }
      this.getReportMonthlyOrderExport(data)
    },
    dispatch(e) {
      this.$emit('input', e)
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      this.$router.push({ name: 'Order View', params: { orderNo: row.orderNo } })
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.deleteOrder(row.orderNo)
        this.tableData.splice(indexToDelete, 1);
      }

    },
    onLink(send_type) {

      console.log(send_type)

      if (send_type == ("ไปรษณีย์ EMS")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("ไปรษณีย์ ลงทะเบียน")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("ไปรษณีย์ COD")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("Kerry")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("Kerry COD")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("Kerry Same Day")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("แมสเซนเจอร์")) {
        //
      } else if (send_type == ("Flash Express")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Lazada")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Shopee")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');

      } else if (send_type == ("SCG")) {
        window.open("https://www.scgexpress.co.th/trackin", '_blank');

      } else if (send_type == ("J&T")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');

      } else if (send_type == ("Flash COD")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Best Exress")) {
        window.open("https://www.best-inc.co.th/track", '_blank');

      } else if (send_type == ("ORANGE EXPRESS")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("NRF Go to laos")) {
        //
      } else if (send_type == ("Tiktok")) {
        //
      } else if (send_type == ("J&T COD")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');
      } else if (send_type == ("Kerry Central")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');
      }



    },
    onSearch() {
      this.searchQuery = ""
      this.searchQueryName = ""
      this.tableData = []

      var day = ""
      if (this.Day == 0) {
        day = ""
      } else {
        day = this.Day
      }



      const data = {
        Day: day,
        Mount: this.Mount,
        Year: this.Year,
      }
      this.getAll(data)
    }
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    // this.getAll()
    this.getWallets()
    const date = new Date();
    this.Mount = date.getMonth() + 1;
    // this.Year =  date.getFullYear();

    this.Day = 0
    var day = ""
    if (this.Day == 0) {
      day = ""
    } else {
      day = this.Day
    }



    const data = {
      Day: day,
      Mount: this.Mount,
      Year: this.Year,
    }
    this.getAll(data)

  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['orderNo'],
      threshold: 0.3
    });
    this.fuseSearchName = new Fuse(this.tableData, {
      keys: ['receiver_name'],
      threshold: 0.3
    });
  },
  watch: {
    uploadTrackList(val) {
      console.log("stockData", val)
      console.log("stockData", val.item.message)



      if (val.item.status == false) {
        swal({
          title: val.item.message,
          text: val.item.message,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'warning'
        }).then(_ => {
          location.reload();
        })
      } else {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_ => {
          location.reload();
        })
      }

      // for (let i = 0; i < val.item.items.length; i++) {
      //    this.tableData.push(val.item.items[i])
      // }

    },
    transaction5(val) {
      console.log("DATA", val.items.data)
      if (parseFloat(val.items.data) < 100) {
        this.check = false
      }
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.1
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },

    searchQueryName(value) {
      let result = this.tableData;
      console.log(value)
      if (value !== '') {
        this.fuseSearchName = new Fuse(this.tableData, {
          keys: this.propsToSearchName,
          threshold: 0.3
        });
        result = this.fuseSearchName.search(this.searchQueryName);
      }
      this.searchedDataName = result;
      this.pagination.currentPage = 1;

    },
    orderList(val) {
      console.log("orderList", val.items)
      this.tableData = val.items.items
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }
    },
    orderHead(val) {
      console.log("orderList", val)

    },


  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
