<template>
    <div class="content">
      <div class="col-md-8 ml-auto mr-auto">
        <h2 class="text-center">แก้ไขรายการสินค้า</h2>
      </div>
      <div class="row mt-5">
        <card>
          <template slot="header">
            <!-- <h4 class="card-title">Form Receive Item</h4> -->
          </template>
          <div>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-2 col-form-label">รหัสลูกค้า</label>
                <div class="col-sm-10">
                  <!-- <base-input placeholder="CUS-xxx" v-model="Model.CustomerNo"></base-input> -->
                  <!-- @change="setItemSelect" -->
                      <el-select
                      disabled
                         class="select-primary"
                          @input="dispatch"
                          size="large"
                          :filterable="false"
                          placeholder="---- กรุณาเลือก ----"
                          v-model="Model.customer_id"
                          @change="setItemSelect"
                          readonly
                        >
                        <el-option
                          class="select-primary"
                          v-for="item in userItems"
                          :key="item.ID"
                          :label="item.cus_id +' : '+item.firstName+'  '+item.lastName"
                          :value="item.ID"
                          readonly
                        >
                        </el-option>
                      </el-select>
  
  
                </div>
              </div>
            
              <div class="row">
                <label class="col-sm-2 col-form-label">SKU</label>
                <div class="col-sm-10">
                  <base-input placeholder="Ex:(A01)" v-model="Model.sku" :maxlength="max" readonly></base-input>
                </div>
              </div>
  
              <div class="row">
                <label class="col-sm-2 col-form-label">ชื่อสินค้า</label>
                <div class="col-sm-10">
                  <base-input placeholder="ชื่อสินค้า" v-model="Model.product_name"></base-input>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-12" style="text-align: center;">
                  <base-button class="mt-auto" native-type="button" type="success" v-on:click="CreateProduct">บันทึกข้อมูล</base-button>
                </div>
              </div>
            </form>
          </div>
        </card>
      </div>
  
    </div>
  </template>
  <script>
  import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
  import {BasePagination} from 'src/components';
  import Fuse from 'fuse.js';
  import swal from 'sweetalert2';
  import {BaseCheckbox, BaseRadio} from 'src/components/index';
  import {mapActions, mapState} from "vuex";
  import moment from 'moment'
  
  export default {
    components: {
      BasePagination,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BaseCheckbox,
      BaseRadio
    },
    computed: {
      ...mapState('users',['user','status','itemData','statusItem','userData','stockData']),
      ...mapState('product',['AddProductStatus' , 'productList' ]),
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        max:4,
        preAddData: {},
        preAddData2: {},
        userItems: {},
        customerFullName:"",
        Model: {
          customer_id: '',
          sku: '',
          product_name: '',
        },
      };
    },
    created() {
      let loginDate = localStorage.getItem('loginDate');
      const current = new Date();
      const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
       console.log("DATA1", date2)
       console.log("DATA2", loginDate)
      if(loginDate.length == 0) {
         this.logout()
      } else {
        if(loginDate != date2) {
           this.logout()
        }
      }
  
      this.getUser()
      console.log(this.$route.params.id)
      const data = {"id" : this.$route.params.id }

      console.log("ID",data);
      this.getProductByID(this.$route.params.id)
    },
    methods: {
       ...mapActions('account', ['logout']),
      ...mapActions('users',['getAll2']),
      ...mapActions('product',['AddProduct' ,'UpdateProduct', 'getProductByID']),
  
      getUser() {
        this.getAll2()
      },
  
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      },
      setItemSelect(data) {
        console.log(data);
        this.preAddData = this.userItems.filter(elem => {
          if (elem.ID === data) return elem;
        })
        console.log(this.preAddData );
        this.customerFullName = this.preAddData[0].firstName + ' ' +  this.preAddData[0].lastName;
      },
  
      async CreateProduct() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
  
        //   if(this.Model.sku.length !=  4){
        //      swal({
        //       title: 'ไม่สามารถบันทึก',
        //       text: `กรุณากรอก sku ให้ครบ 4 หลัก`,
        //       type: 'error',
        //     });
        //     return
        //   }
  
  
          const product = this.Model

          const data = {"ID" : this.$route.params.id,
          "product_name" :  this.Model.product_name

         }
        

          this.UpdateProduct(data)
        }
      },
  
  
    },
    mounted() {
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ['barcode', 'lot'],
        threshold: 0.3
      });
    },
    watch: {
      AddProductStatus(val){
        console.log(val);
        if(val.message === "success") {
          swal({
            title: `บันทึกข้อมูลสำเร็จ!`,
            text: 'บันทึกข้อมูลสำเร็จ!',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          }).then(_=>{
              this.$router.push('/product/list')
          })
        } else {
          swal({
              title: 'ไม่สามารถบันทึก',
              text: `กรุณาตรวจจสอบข้อมูล`,
              type: 'error',
            });
        }
      },
      productList(val) {
        console.log(val.items.data.customer_id)
        this.Model.customer_id = val.items.data.customer_id
        this.Model.sku = val.items.data.SKU
        this.Model.product_name = val.items.data.ProductName
      },
      userData(val) {
        console.log(val.items.items);
        this.userItems = val.items.items
      },
    }
  };
  </script>
  <style>
  
  .el-select {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .pagination-select,
  .search-input {
    width: 200px;
  }
  </style>
  