<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการสินค้า</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">

          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">
                <router-link to="/product/create" >
                  <base-button type="success" class="animation-on-hover">เพิ่มสินค้า</base-button>&nbsp;
                </router-link>
              </div>
              <div class="mb-6">
              </div>
               <div class="mb-3">
                 <base-button type="danger" class="animation-on-hover">Import</base-button>
              </div>
            </div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <el-table :data="queriedData">

             <el-table-column :min-width="100" align="left" label="การกระทำ">
                <div slot-scope="props">


                  <el-tooltip
                    content="แก้ไขรายละเอียด"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                    <base-button
                      @click.native="editBox(props.$index, props.row)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>


            

                   <el-tooltip
                    content="เปลี่ยนสถานะ"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                      <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                        class="like btn-link"
                        type="danger"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-refresh-01"></i>
                      </base-button>
                   </el-tooltip>




                </div>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>

              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.Status == 'ACTIVE'">ACTIVE</div> 

                  <div class="badge badge-danger" v-if="props.row.Status == 'INACTIVE'">INACTIVE</div> 
                </div>
              </el-table-column>

              <!-- @click.native="handleEdit(props.$index, props.row)" -->
              <!-- <el-table-column :min-width="100" align="right" label="การกระทำ">
                <div slot-scope="props">
                  <base-button
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon>
                    <i class="tim-icons icon-zoom-split"></i>
                  </base-button>

                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import {mapActions, mapState} from "vuex";
import { productService } from '@/services';
import { userService } from '@/services';

import swal from 'sweetalert2';




export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('users', ['stockData']),
    ...mapState('product', ['productList']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      hidden : false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      stockItems: {},
      searchQuery: '',
      tableColumns: [
        {
          prop: 'SKU',
          label: 'SKU',
          minWidth: 200
        },
        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 200
        },
          {
          prop: 'CustomerCode',
          label: 'รหัสลูกค้า',
          minWidth: 200
        },
         {
          prop: 'Amount',
          label: 'จำนวนคงเหลือ',
          minWidth: 120
        },


      ],
      propsToSearch: ['SKU' , 'ProductName' , 'CustomerCode'],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

     this.getDataProduct()
  },
  methods: {
     ...mapActions('account', ['logout']),
    ...mapActions('product', ['getProduct']),
    getDataProduct() {
      this.getProduct()
    },

    editBox(index,row) {
      this.$router.push('/product/edit/'+row.ID  )
    },

    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Change Status',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Change Status!',
            text: `You Change Status ${row.ProductName}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        //TODO :: Move to State Watcher

        console.log(row)
        console.log(row.Status)

        var status = "";
        if(row.Status == "ACTIVE") {
          status = "INACTIVE"
        } else {
          status = "ACTIVE"
        }


         const data =  {
          "ID": row.ID,
          "product_status": status,
        }

        console.log(data)


        userService.deleteProduct2(data).then(_ =>{
          //  this.tableData.splice(indexToDelete, 1);
           this.getDataProduct();
        })
      }
    },
  },


  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['SKU' , 'ProductName' , 'CustomerCode'],
      threshold: 0.3
    });
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
     productList(val) {
      this.tableData = val.items.items
    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
