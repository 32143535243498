<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">สแกนรายการขึ้นรถ</h2>
    </div>
    <div class="row mt-5">



      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">



              <div class="col-md-10">
                <base-input class="mb-3 ml-2" type="text" v-model="orderNo" @keyup.enter="Picking"
                  placeholder="Scan Order No.">

                </base-input>
              </div>

              <div class="col-md-2">
                <base-button class="mt-auto ml-2" native-type="button" type="info" v-on:click="Picking">
                  ยืนยัน
                </base-button>
              </div>


            </div>


            <el-table :data="queriedData" class="mt-3">

              <el-table-column label="วันที่" :min-width="180">
                <div slot-scope="props">
                  <div>{{ props.row.date_order }}</div>
                </div>
              </el-table-column>

              <el-table-column label="เลขรายการสั่งซื้อ" :min-width="150">
                <div slot-scope="props">
                  <div>{{ props.row.orderNo }}</div>
                </div>
              </el-table-column>


              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>

              <el-table-column :min-width="150" align="center" label="รูปแบบการส่ง">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>

                </div>
              </el-table-column>




            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import { Modal, BaseAlert } from 'src/components';
import moment from 'moment'



export default {
  components: {
    Modal,
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin', 'saveOrderStatus', 'orderStatusScan']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {

      orderNo: "",
      weightModal: "",
      modals: {
        notice: false,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      tableColumns: [
        {
          prop: 'sender_code',
          label: 'รหัสลูกค้า',
          minWidth: 100
        },
        {
          prop: 'customer_name',
          label: 'ชื่อลูกค้า',
          minWidth: 200
        },

        {
          prop: 'receiver_name',
          label: 'ชื่อผู้รับ',
          minWidth: 200
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    let user = JSON.parse(localStorage.getItem('users'));
    console.log(user)
    console.log(user.ID)
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['confirmPackingOrder2']),
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
      }
    },
    async Picking() {
      if (!this.orderNo) {
        swal("กรุณากรอกเลข Order No")
        return false
      }

      if (this.orderNo === "" || this.orderNo === null) {
        swal("กรุณากรอกเลข Order No")
        return false
      }
      console.log(this.orderNo)
      let user = JSON.parse(localStorage.getItem('users'));
      console.log(user)
      const data = {
        order_no: this.orderNo,
        staff_id: user.ID,

      }

      this.orderNo = ""
      console.log(data)
      this.confirmPackingOrder2(data)
      //  swal({
      //     title: 'คุณแน่ใจไหม?',
      //     text: `คุณต้องการนำ order นี้ขึ้นรถใช่ไหม?`,
      //     type: 'warning',
      //     showCancelButton: true,
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-danger btn-fill',
      //     confirmButtonText: 'Yes it!',
      //     buttonsStyling: false
      //   }).then(result => {
      //     if (result.value) {
      //       this.confirmPackingOrder(data)
      //     }
      //   });

    },
    handleView(index, row) {
      // this.$router.push({name:'Order Car View',params:{orderNo:row.orderNo}})
      this.$router.push('/picking-car/detail/' + row.orderNo)
    },
    handModal(index, row) {
      // this.$router.push({name:'Order Car View',params:{orderNo:row.orderNo}})
      this.orderNo = row.orderNo;
      let user = JSON.parse(localStorage.getItem('users'));
      //  this.modals.notice = true;
      const data = {
        order_no: this.orderNo,
        weight: user.ID,

      }
      console.log(data)
      swal({
        title: 'คุณแน่ใจไหม?',
        text: `คุณต้องการนำ order นี้ขึ้นรถใช่ไหม?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.confirmPackingOrder2(data)
        }
      });
    },

  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      threshold: 0.3
    });
  },
  watch: {
    saveOrderStatus(val) {
      console.log(val.data)


      this.tableData.push({
        sender_code: val.data.data.customercode,
        customer_name: val.data.data.customername,
        receiver_name: val.data.data.receivername,
        send_type: val.data.data.sendtype,
        date_order: val.data.data.createddate,
        orderNo: val.data.data.orderno,
      });

      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }

    },

    orderListAdmin(val) {
      this.tableData = val.items.items
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
