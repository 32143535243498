<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายละเอียดสินค้า</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูล</h4>
            </template>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-2 col-form-label">SKU</label>
                <div class="col-sm-8">
                  <base-input placeholder="SKU" v-model="model.SKU" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">ชื่อสินค้า</label>
                <div class="col-sm-8">
                  <base-input placeholder="ชื่อสินค้า" v-model="model.ProductName" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">จำนวน</label>
                <div class="col-sm-8">
                  <base-input placeholder="จำนวน" v-model="model.Amount" readonly></base-input>
                </div>
              </div>


            </form>
          </card>

        </div>

        <!-- <div class="col-md-12">
          <card>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-2 col-form-label">Lazada</label>
                <div class="col-sm-8">
                  <el-select class="select-primary" placeholder="---- กรุณาเลือก ----"  size="large"
                    :filterable="true" v-model="Item">
                    <el-option class="select-primary" v-for="data in ItemType" :key="data.SKU" :label="data.ProductName.substring(0,15)+' | SKU: '+ data.SKU"
                      :value="data.SKU">
                    </el-option>
                  </el-select>
                </div>
              </div>

            </form>
          </card>

        </div> -->
      </div>
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">รายละเอียดเพิ่มเติม</h4>
            </template>
            <div>
              <form method="get" action="/" class="form-horizontal">
                <div class="row mb-3">
                  <label class="col-sm-2 col-form-label">รูปภาพ</label>
                </div>
                <div class="row">
                  <label class="col-sm-1 col-form-label"></label>
                  <image-upload @change="onImageChange" select-text="เลือกรูปภาพ" :src="model.Image" />
                </div>

              </form>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 center" style="text-align: center;">
          <base-button class="mt-auto" native-type="button" type="success" v-on:click="SaveImage">แก้ไข
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker, Option, Select, Table, TableColumn } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { mapActions, mapState } from "vuex";
import moment from 'moment'
import zipcode from '@/helpers/zipcode.json'
import {
  ImageUpload,
} from 'src/components/index';
import { userService } from '@/services';
import { productService } from '../../services/product.service';
import { throws } from 'assert';


export default {
  components: {
    ImageUpload,
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users', ['user', 'status', 'itemData', 'statusItem', 'stockData']),
    ...mapState('orders', ['orderStatus', 'zipList']),
    ...mapState('product',['productLazadaList']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: false,
      CustomerID: "",
      customerFullName: "",
      preAddData: {},
      stockItems: {},
      imageBase64: null,
      sku: null,
      model: {
        Amount: null,
        CreatedAt: null,
        DeletedAt: null,
        ID: null,
        Image: null,
        ProductName: null,
        SKU: null,
        Status: null,
      },
      ItemType: [
        { ID: 1, Name: "Lazada" },
      ],
      Item: null,
      searchQuery: '',
      propsToSearch: ['barcode'],

    };
  },
  created() {
   // console.log(this.$route.params.id)
    userService.getMeProductDetail(this.$route.params.id).then(users => {
      console.log(users)
      this.model = users.data
      this.sku = users.data.SKU

      this.ItemType = [
        { ID: 1, Name: this.sku + "Lazada" },
      ];
    })
    // productService.getLazadaProduct().then(products => {
    //   console.log("Lazada",products)
    //   this.ItemType = products.items
    // })
    this.getLazadaProduct()
    //this.getZipCode()
  },
  methods: {
    ...mapActions('users', ['getMeStock', 'getUserByCode', 'getItemByCode', 'getLastItemCode', 'getItemCustomerByCode', 'getMeProductDetail','getLazadaProduct']),
    ...mapActions('orders', ['SaveOrderData', 'clearStateStatus', 'uploadProductImage', 'getZipCode']),
    ...mapActions('product',['getLazadaProduct']),
    filter(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false
        }, 200)
      } else {
        this.clients = []
      }
    },
    onImageChange(file) {
      console.log(file)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageBase64 = reader.result;
      }
    },

    SaveImage() {

      if (this.imageBase64 == null) {
        swal({
          title: 'ไม่สามารถแก้ไขได้',
          text: `กรุณาเลือกรูปภาพ`,
          type: 'error',
        });
        return
      }


      const data = {
        sku: this.sku,
        image: this.imageBase64
      }
      this.uploadProductImage({ data })
    },

  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    orderStatus(val) {
      if (val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_ => {
          this.$router.push('/stock/list')
        })
      }
      this.clearStateStatus()
    },
    productLazadaList(val){
      console.log("ProductLazadaList", val.items.items)
      this.ItemType = val.items.items
    },
    zipList(val) {
      // this.zipcodeList = val.items.data;
      this.zipcodeList = [];
      for (let i = 0; i < val.items.data.length; i++) {
        this.zipcodeList.push({ ID: val.items.data[i], Value: val.items.data[i] })
      }

    },
  }
};
</script>
<style>
.el-select {
  width: 100%;
}

.pagination-select,
.search-input {
  width: 100%;
}
</style>
