<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการจัดของ {{ customerID }}</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">

          <div>

            
             
              
            <div class="col-md-12 d-flex justify-content-end flex-wrap">
              <base-button type="success" class="animation-on-hover" @click="confirmPick">ยืนยันรายการ</base-button>&nbsp;
              <base-button type="info" class="animation-on-hover" @click="Print">พิมพ์</base-button>&nbsp;
            </div>

            <div class="row mt-5 mb-2 ml-1">
              <div class="col-md-3">
                <h4>ชั้นวาง</h4>  
              </div>
              <div class="col-md-9">
                {{ location_list }}
              </div>
              
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div class="badge badge-success">{{props.row.status}}</div>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="col-md-12" v-show="false" id="picking_table">
      <card card-body-classes="table-full-width">
        <h2 class="text-center">รายการจัดของ {{ customerID }}</h2>
      <div class="table-responsive mt-auto">
        <table class="table table-bordered" style="border-collapse: collapse">
          <thead>
            <th style="font-size: 12px;width: 100px;border: 1px solid black" v-for="data in tableColumnsPrint" v-bind:key="data">{{data}}</th>
          </thead>
          <tbody>
            <tr v-for="(item,index) in tableData" v-bind:key="index">
              <td style="font-size: 12px;width: 100px;border: 1px solid black;">{{ index+1}}</td>
              <td style="font-size: 12px;width: 100px;border: 1px solid black; text-align: center;">{{ item.product_no}}</td>
              <td style="font-size: 12px;width: 100px;border: 1px solid black;">{{ item.product_name}}</td>
              <td style="font-size: 12px;width: 100px;border: 1px solid black; text-align: center;">{{ item.amount}}</td>
            </tr>
          </tbody>
        </table>

          <div class="row mt-5 mb-2 ml-1">
              <div>
                <h4 class="text-center">ชั้นวาง</h4>
              </div>
              <div style="font-size: 10px">
                {{ location_list }}
              </div>
              
            </div>


      </div>
      </card>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['pickingList' , 'orderLocation']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      customerID: "",
      location_list: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10,25,50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['product_no', 'product_name', 'status'],
      tableColumnsPrint:['ลำดับ','รหัส','ชื่อสินค้า','จำนวน'],
      tableColumns: [
        {
          prop: 'product_no',
          label: 'รหัส',
          minWidth: 200
        },
        {
          prop: 'product_name',
          label: 'ชื่อสินค้า',
          minWidth: 300
        },
        {
          prop: 'amount',
          label: 'จำนวน',
          minWidth: 200
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {

    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['pickingOrder', 'confirmPicking' , 'getlocation']),
    Print() {
      this.$htmlToPaper('picking_table')
    },
    confirmPick(index, row) {
      swal({
        title: 'แน่ใจหรือไม่?',
        text: `แน่ใจหรือไม่ ที่จะยืนยันรายการนี้!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, Confirm it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          let data = {
            customer_id: this.customerID
          }
          this.confirmPicking(data)
          this.$router.push('/order/adminOrderList')

        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    this.customerID = this.$route.params.customer
    this.pickingOrder(this.customerID)
    this.getlocation(this.customerID)
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['product_no', 'product_name', 'status'],
      threshold: 0.3
    });
  },
  watch: {
    searchQuery(value) {
      console.log("DATA4", val)
      console.log(value)
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;
    },
    pickingList(val) {
      console.log("DATA5", val)
      this.tableData = val.data.items
    },
    orderLocation(val) {
      console.log("DATA1", val.data.location_list)
      this.location_list = val.data.location_list
     
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
