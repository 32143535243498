<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">ประวัติการหยิบของ {{this.recTime}}</h2>
    </div>


      <div class="col-md-12" v-show="false" id="picking_table">
          <card card-body-classes="table-full-width">
            <h2 class="text-center" >รายการหยิบของ {{recData}}</h2>
            <h3 class="text-center" >{{recData2}}</h3>
          <div class="table-responsive mt-auto">
            <table class="table table-bordered" style="border-collapse: collapse; text-align: center;">
              <thead>
                <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                <th style="font-size: 12px;width: 100px;border: 1px solid black">SKU</th>
                <th style="font-size: 12px;width: 100px;border: 1px solid black">ชื่อสินค้า</th>
                <th style="font-size: 12px;width: 100px;border: 1px solid black">จำนวนรายการ</th>
              </thead>
              <tbody>
                <tr v-for="(item,index) in this.tableDataModalPrint" v-bind:key="item.Barcode">
                  <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                  <td style="font-size: 12px;width: 100px;border: 1px solid black; text-align: center;">{{ item.Barcode}}</td>
                  <td style="font-size: 12px;width: 160px;border: 1px solid black;">{{ item.ProductName}}</td>
                  <td style="font-size: 12px;width: 100px;border: 1px solid black; text-align: center;">{{ item.Total}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </card>
        </div>


          <modal
            :show.sync="modals.notice"
            headerClasses="justify-content-center"
          >
            <h4 slot="header" class="title title-up">{{dataModal}}</h4>

                <table style="border-collapse: collapse;">
            <tr>
              <th style="font-size: 14px;width: 100px;border: 1px solid black;text-align: center;">ลำดับ</th>
              <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">เลขรายการสั่งซื้อ</th>
              <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">ชื่อผู้รับ</th>
            </tr>
            <tr v-for="(column , index) in tableDataModal" v-bind:key="column.OrderNo">
             <td  style="font-size: 14px;width: 100px;border: 1px solid black;text-align: center;">
                {{index+1}}
             </td>
              <td  style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">
                {{ column.OrderNo }}
              </td>
                <td  style="font-size: 14px;width: 350px;border: 1px solid black;text-align: center;">
                {{ column.ReceiverName }}
              </td>

            </tr>
          </table>


        </modal>




    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
             <div class="col-md-3">
                 <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
             </div>

              <div class="col-md-6">
              </div>

               <div class="col-md-3">
                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
               </div>
            </div>
            <el-table :data="queriedData">
              <el-table-column :min-width="135" align="left" label="การกระทำ">
                  <div slot-scope="props">

                      <el-tooltip
                          content="รายละเอียด"
                          effect="dark"
                          :open-delay="300"
                          placement="top">
                            <base-button
                              @click.native="handleView(props.$index, props.row)"
                              class="edit btn-link"
                              type="warning"
                              size="sm"
                              icon
                            >
                              <i class="tim-icons icon-zoom-split"></i>
                            </base-button>
                      </el-tooltip>


                       <el-tooltip
                          content="ปริ้น"
                          effect="dark"
                          :open-delay="300"
                          placement="top">

                          <base-button
                            @click.native="handlePrint(props.$index, props.row)"
                            class="edit btn-link"
                            type="info"
                            size="sm"
                            icon
                          >
                            <i class="tim-icons icon-paper"></i>
                          </base-button>
                       </el-tooltip>
                  </div>
              </el-table-column>




              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>

             <el-table-column :min-width="200" align="left" label="ชื่อลูกค้า">
                <div slot-scope="props">
                {{props.row.first_name}}   {{props.row.last_name}}
                </div>
              </el-table-column>

               <el-table-column :min-width="170" align="left" label="จำนวนรายการ">
                  <div slot-scope="props">
                  {{props.row.total_order}}
                  </div>
              </el-table-column>
             <!--- <el-table-column label="สถานะ" :min-width="140">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status ==='NEW'">NEW</div>
                  <div class="badge badge-info" v-if="props.row.status ==='PICKING'">PICKING</div>
                  <div class="badge badge-success" v-if="props.row.status ==='COMPLETED'">COMPLETED</div> &nbsp;
                  <div class="badge badge-warning" v-if="props.row.status ==='COMPLETED'">TRACKED</div>
                </div>
              </el-table-column> --->

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>










    </div></div
></template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";
import moment from 'moment'
import { Modal, BaseAlert } from 'src/components';



export default {
  components: {
    Modal,
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin' , 'orderListAdmin2' , 'orderListAdmin3']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
       modals: {
        notice: false,
      },
      recTime:'',
      recData: '',
      recData2: '',
      dataModal:'',
      searchQuery: '',
      propsToSearch: ['customer_code', 'first_name' , 'last_name' ,'total_order'],
      tableColumns: [

          {
          prop: 'customer_code',
          label: 'รหัสลูกค้า',
          minWidth: 170
        },



      ],
      tableData: [],
      tableData2: [],
      tableDataModal: [],
      tableDataModalPrint: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    // this.adminOrders()
     this.recTime = this.format_date(this.$route.params.orderTime)
     var data = {
       'time' : this.$route.params.orderTime
     }

     this.getOrderHistoryListDetail(data);


  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['adminOrders','getOrderHistoryListDetail','getOrderHistoryListDetail2' ,'getOrderHistoryListDetail3' , 'getOrderTransaction']),
     format_date(value){
         if (value) {
            var startdate = moment(String(value)).add(7, 'hours').format('DD/MM/YYYY (HH.mm)')
           return startdate
          }
    },
    handleView(index, row) {
      this.dataModal = this.format_date(this.$route.params.orderTime)
      this.modals.notice = true
       var data2 = {
          'time' : this.$route.params.orderTime,
          'customer_code': row.customer_code
        }
        this.getOrderHistoryListDetail2(data2);
    },



    handlePrint(index, row) {
        var data3 = {
          'time' : this.$route.params.orderTime,
          'customer_code': row.customer_code
        }
        this.getOrderHistoryListDetail3(data3);

        'first_name' , 'last_name'

        this.recData = row.first_name + " "+row.last_name;
        this.recData2 = this.format_date(this.$route.params.orderTime)

    },



    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys:    ['customer_code', 'first_name' , 'last_name' ,'total_order'],
      threshold: 0.3
    });
  },
  watch: {
    orderListAdmin(val){
      this.tableData = val.items.items
    },
     orderListAdmin2(val){
      this.tableDataModal = val.items.items
    },
    orderListAdmin3(val){
      this.tableDataModalPrint = val.items.items
      if(this.tableDataModalPrint.length > 0){
          console.log(this.tableDataModalPrint.length)
          setTimeout(() =>  this.$htmlToPaper('picking_table'), 500);
      }

    },




     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>

.pagination-select,
.search-input {
  width: 200px;
}
</style>
