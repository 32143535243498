<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการกล่อง</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">


          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">
                <router-link to="/box/create" >
                  <base-button type="success" class="animation-on-hover">เพิ่มกล่อง</base-button>&nbsp;
                </router-link>
              </div>
            </div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <el-table :data="queriedData">


            <el-table-column :min-width="150" align="left" label="การกระทำ">

                <div slot-scope="props">

                  <el-tooltip
                    content="แก้ไขรายละเอียด"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                    <base-button
                      @click.native="editBox(props.$index, props.row)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon>
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>

                   <el-tooltip
                    content="เปลี่ยนสถานะ"
                    effect="dark"
                    :open-delay="300"
                     placement="top">
                      <base-button
                        @click.native="handleDelete(props.$index, props.row)"
                        class="like btn-link"
                        type="danger"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-refresh-01"></i>
                      </base-button>
                   </el-tooltip>

                    <el-tooltip
                      content="ซื้อกล่อง"
                      effect="dark"
                      :open-delay="300"
                      placement="top">
                      <base-button
                        @click.native="editBox2(props.$index, props.row)"
                        class="like btn-link"
                        type="warning"
                        size="sm"
                        icon>
                        <i class="tim-icons icon-app"></i>
                      </base-button>
                    </el-tooltip>
                   

                </div>

            </el-table-column>


              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>


                <el-table-column label="สถานะ" :min-width="140">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status ==='1'">ใช้งาน</div>
                  <div class="badge badge-danger" v-if="props.row.status ==='0'">ไม่ได้ใช้งาน</div>
                </div>
              </el-table-column>

              <!-- @click.native="handleEdit(props.$index, props.row)" -->

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";



export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['boxList' , 'orderStatus']),
   queriedData() {
       let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },

      stockItems: {},
      searchQuery: '',
      propsToSearch: ['cost', 'name', 'price' , 'amount'],
      tableColumns: [
        {
          prop: 'name',
          label: 'ชื่อกล่อง',
          minWidth: 150
        },
        {
          prop: 'price',
          label: 'ราคา',
          minWidth: 120
        },
        {
          prop: 'cost',
          label: 'ต้นทุน',
          minWidth: 120
        },
        {
          prop: 'amount',
          label: 'จำนวน',
          minWidth: 120
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

     this.getStock()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', [ 'getBoxAll' , 'updateBoxStatus']),
    getStock() {
      this.getBoxAll()
    },
     editBox(index,row) {
      this.$router.push('/box/edit/'+row.id)
    },
    editBox2(index,row) {
      this.$router.push('/box/buy/'+row.id)
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
     handleDelete(index, row) {

       console.log(row.status)

      if(row.status == "0"){
        const data = {
         "ID": row.id,
        "status": "1"
       }

        this.updateBoxStatus(data)

      } else {
        const data = {
         "ID": row.id,
        "status": "0"
       }

        this.updateBoxStatus(data)
      }


    },
    deleteRow(row) {

      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.uid === row.uid
      );
      if (indexToDelete >= 0) {
        //TODO :: Move to State Watcher


        const data =  {
          "ID": row.uid,
          "userName": row.user_name
        }

         userService.deleteUser(data).then(_ =>{
           this.tableData.splice(indexToDelete, 1);
           this.getStock();
        })
      }
    },
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['cost', 'name', 'price' , 'amount'],
      threshold: 0.3
    });
  },
  watch: {
    orderStatus(val) {
       this.getStock();
    },
     boxList(val) {

      console.log("DATA",val.items)
      console.log(val.items.data)
      this.tableData = val.items.data;
    },

     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
     stockData(val) {
      console.log("stockData", val.items)
      this.tableData = val.items.data
    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
