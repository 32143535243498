<template>
  <div class="container" style="  padding-top: 150px;padding-bottom: 150px;">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="handleSubmit">
        <card class="card-login card-white">
          <template slot="header">
            <!-- <img src="img/card-primary.png" alt="" /> -->
            <!-- <h1 class="card-title"></h1> -->
          </template>

          <img class="photo" src="img/NRF_LOGO.jpg" alt=""  style="border-radius: 50%;height:150px; text-align: center;display: block;margin: 50px auto;"/>



          <div class="mt-3">
            <base-input
              v-validate="'required'"
              name="email"
              :error="getError('email')"
              v-model="model.email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required|min:3'"
              name="password"
              :error="getError('password')"
              v-model="model.password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="success" class="mb-3" size="lg" block>
              เข้าสู่ระบบ
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';


export default {
   components: {
    TabPane,
    Tabs,
    Collapse,
    CollapseItem
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        subscribe: true
      }
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },

  methods: {
    ...mapActions('account', ['login']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async handleSubmit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        //await router.push('dashboard')
        console.log("Login")
        const username = this.model.email;
        const password = this.model.password
        await this.login({ username, password})
      }
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
