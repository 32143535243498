<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">ประวัติการหยิบของ</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
             <div class="col-md-3">
                 <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
             </div>

              <div class="col-md-6">
              </div>

               <div class="col-md-3">
                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
               </div>
            </div>
            <el-table :data="queriedData">
              <el-table-column :min-width="135" align="left" label="การกระทำ">
                  <div slot-scope="props">

                     <el-tooltip
                      content="รายละเอียด"
                      effect="dark"
                      :open-delay="300"
                      placement="top">
                    <base-button
                      @click.native="handleView(props.$index, props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                       <i class="tim-icons icon-zoom-split"></i>
                    </base-button>
                     </el-tooltip>
                  </div>
              </el-table-column>


              <el-table-column label="วันที่เวลา" :min-width="180">
                <div slot-scope="props">
                  <div>{{ props.row.time2 }}</div>
                </div>
              </el-table-column>



              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
             <!--- <el-table-column label="สถานะ" :min-width="140">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status ==='NEW'">NEW</div>
                  <div class="badge badge-info" v-if="props.row.status ==='PICKING'">PICKING</div>
                  <div class="badge badge-success" v-if="props.row.status ==='COMPLETED'">COMPLETED</div> &nbsp;
                  <div class="badge badge-warning" v-if="props.row.status ==='COMPLETED'">TRACKED</div>
                </div>
              </el-table-column> --->

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div></div
></template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";
import moment from 'moment'


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['time2', 'total_order'],
      tableColumns: [

        {
          prop: 'total_order',
          label: 'จำนวนรายการ',
          minWidth: 170
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    this.getOrderHistoryList()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['adminOrders','getOrderHistoryList']),
     format_date(value){
         if (value) {
           var startdate = moment(String(value)).add(7, 'hours').format('DD/MM/YYYY (HH.mm)')
           return startdate
          }
    },
    handleView(index, row) {
      this.$router.push('/order/pick-history/detail/' + row.time)
      // this.$router.push({name:'Pick List',params:{customer:row.customer_code}})
    },

    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys:   ['time2', 'total_order'],
      threshold: 0.3
    });
  },
  watch: {
    orderListAdmin(val){
      this.tableData = val.items.items
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].time2 = this.format_date(this.tableData[i].time)
      }
    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>

.pagination-select,
.search-input {
  width: 200px;
}
</style>
