<template>
<div>
   <div class="content" style="  padding-top: 100px;padding-bottom: 100px; background-color: #ffffff;">


    <div class="col-md-8 ml-auto mr-auto mt-3">
      <h2 class="text-center"  style=" color: #4CAF50">ติดต่อเรา</h2>
    </div>


      <form class="form-horizontal" @submit.prevent="sendData">




            <div class="row mt-3">
              <label class="col-sm-2 col-form-label">ชื่อ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('First Name')"
                  v-model="model.name"
                  name="First Name"
                  placeholder="ชื่อ"

                >
                </base-input>
              </div>
            </div>


            <div class="row">
              <label class="col-sm-2 col-form-label">อีเมล์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|email'"
                  :error="getError('email')"
                  v-model="model.email"
                  name="email"
                  placeholder="อีเมล์"

                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Phone Number')"
                  v-model="model.phoneNumber"
                  name="phoneNumber"
                  placeholder="เบอร์โทรศัพท์"

                >
                </base-input>
              </div>
            </div>


            <div class="row">
              <label class="col-sm-2 col-form-label">ที่อยู่</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('ที่อยู่')"
                  v-model="model.address"
                  name="ที่อยู่"
                  placeholder="ที่อยู่"

                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">หัวข้อ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('หัวข้อ')"
                  v-model="model.title"
                  name="หัวข้อ"
                  placeholder="หัวข้อ"

                >
                </base-input>
              </div>
            </div>

             <div class="row">
                <label class="col-sm-2 col-form-label">รายละเอียด</label>
                <div class="col-sm-10">
                   <base-textarea placeholder="รายละเอียด"
                   v-validate="'required'"
                  :error="getError('รายละเอียด')"
                  name="รายละเอียด"
                   v-model="model.detail"/>
                </div>
              </div>

            <div class="row mt-3">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="SendMail">ส่งเมล</base-button>
              </div>

              <div class="col-md-6" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="warning" v-on:click="Line">line</base-button>
              </div>

              <div class="col-md-6" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="primary" v-on:click="Facebook">facebook</base-button>
              </div>
            </div>
          </form>
  </div>
</div>

</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseTextarea from "@/components/Inputs/BaseTexarea";

// Vue.use(VueMq, {
//   breakpoints: {
//     mobile: 450,
//     tablet: 900,
//     laptop: 1250,
//     desktop: Infinity,
//   }
// })


export default {

 components: {
    BaseTextarea,

  },
  data() {
    return {
      model: {
        // isMoblie: false,
        // isTablet: false,
        // isDesktop: false,
        email: '',
        password: '',
        subscribe: true,


      }
    };
  },
  computed: {
    ...mapState('account', ['status'])

  },
  created() {


    console.log(screen.width)
    if(screen.width <= 480){
      this.isMoblie = true
    } else  if(screen.width <= 768){
      this.isTablet = true
    } else {
       this.isDesktop = true
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async handleSubmit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        //await router.push('dashboard')
        console.log("Login")
        const username = this.model.email;
        const password = this.model.password
        await this.login({ username, password})
      }
    },
    SendMail() {
console.log("AAAAA")
    },
    Print(){
      console.log("AAAAA")

    },
     Line(){
      console.log("AAAAA")
        window.open("https://lin.ee/nguNOjS", '_blank');
    },
    Facebook(){
      console.log("AAAAA")
        window.open("https://www.facebook.com/NRICH-Fulfillment-102041058420372", '_blank');
    },



    isMoblie() {
        if( screen.width <= 480 ) {
            return true;
        }

    },
     isTablet() {
        if( screen.width > 480 && screen.width <= 768 ) {
            return true;
        }

    },
     isDesktop() {
        if( screen.width >768 ) {
            return true;
        }

    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

</style>
