<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Order Detail : {{ orderNo }}</h2>
    </div>

    <div class="row mt-2">
     <div class="col-md-12">
          <card>
            <div class="mt-5">
              <h4 slot="header" class="card-title">รายการสินค้า</h4>
              <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <base-input type="text" placeholder="Barcode" v-model="barcodeValue" @keyup.enter="Picked"></base-input>
              </div>
              <el-table :data="queriedData">
               <el-table-column label="รูปภาพ" :min-width="200">
                <div slot-scope="props" >

                   <img class="card-img" :src="props.row.Image"  onerror="javascript:this.src='img/image-not-found.png'"/>
                </div>
              </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>
          </card>
     </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูลผู้ส่ง</h4>
            </template>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <label class="col-sm-3 col-form-label">รหัสลูกค้า</label>
                <div class="col-sm-9">
                  <base-input placeholder="CUS-xxx" v-model="Sender.CustomerNo" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">เบอร์ติดต่อ</label>
                <div class="col-sm-9">
                  <base-input placeholder="090000000x" v-model="Sender.CustomerMobile" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">ที่อยู่</label>
                <div class="col-sm-9">
                  <base-input placeholder="address" v-model="Sender.CustomerAddress" readonly></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
                <div class="col-sm-9">
                  <base-input placeholder="address" v-model="Sender.CustomerZipcode" readonly></base-input>
                </div>
              </div>
            </form>
          </card>
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูลผู้รับ</h4>
            </template>
            <div class="col-md-12">
              <form method="get" action="/" class="form-horizontal">
                <div class="row">
                  <label class="col-sm-3 col-form-label">ชื่อ-นามสกุล</label>
                  <div class="col-sm-9">
                    <base-input placeholder="name" v-model="Receiver.Name" readonly></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">เบอร์ติดต่อ</label>
                  <div class="col-sm-9">
                    <base-input placeholder="090000000x" v-model="Receiver.Mobile" readonly></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">ที่อยู่</label>
                  <div class="col-sm-9">
                    <base-input placeholder="address" v-model="Receiver.Address" readonly></base-input>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
                  <div class="col-sm-9">
                    <base-input placeholder="address" v-model="Receiver.Zipcode" readonly></base-input>

                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">ยอดเก็บเงินปลาทาง</label>
                  <div class="col-sm-9">
                    <base-input placeholder="0" v-model="Receiver.COD" readonly></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">รูปแบบการส่ง</label>
                  <div class="col-sm-9 mb-2">
                    <el-select
                      class="select-primary mb-8"
                      placeholder="---- กรุณาเลือก ----"
                       @input="dispatch"
                      size="large"
                      :filterable="true"
                      v-model="Receiver.SendType" disabled>
                      <el-option
                        class="select-primary"
                        v-for="data in SendType"
                        :key="data.ID"
                        :label="data.Name"
                        :value="data.Name"

                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">REF 1</label>
                  <div class="col-sm-9">
                    <base-input  v-model="Receiver.Ref1" readonly></base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 col-form-label">REF 2</label>
                  <div class="col-sm-9">
                    <base-input v-model="Receiver.Ref2" readonly></base-input>
                  </div>
                </div>
              </form>
            </div>
          </card>


          <card>
            <template slot="header">
              <h4 class="card-title">Order Transaction</h4>
            </template>
            <div class="col-md-12">

            <div style="overflow-x:auto;">
              <table class="table table-bordered" style="border-collapse: collapse; text-align: center;width 100%">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                  <th style="font-size: 12px;border: 1px solid black">วันที่เวลา</th>
                  <th style="font-size: 12px;width: 200px;border: 1px solid black">ผู้ดำเนินการ</th>
                  <th style="font-size: 12px;border: 1px solid black">สถานะ</th>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in this.tableDataOrder" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.time}}</td>
                    <td style="font-size: 12px;width: 200px;border: 1px solid black; text-align: center;">{{ item.created_by}}</td>
                    <td style="font-size: 12px;border: 1px solid black; text-align: center;">{{ item.type}}</td>
                  </tr>
                </tbody>
              </table>
            </div>


            </div>
          </card>



        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">ข้อมูลบริการ</h4>
            </template>
            <div class="form-horizontal">
              <div class="row">
                <label class="col-sm-3 col-form-label">Tracking</label>
                <div class="col-sm-9">
                  <base-input
                 :disabled="check"
                    name="tracking"
                    :error="getError('tracking')"
                    placeholder="-"
                    v-model="Tracking"></base-input>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-3 col-form-label">ขนาดกล่อง</label>
                <div class="col-sm-9">
                  <el-select
                    :disabled="check"
                    ref="BoxSize"
                    name="boxSize"
                    v-validate="'required'"
                    :error="getError('boxSize')"
                     @change="onChange($event)"
                    class="select-primary mb-8"
                    placeholder="---- กรุณาเลือก ----"
                    v-model="BoxSize">
                   <el-option
                      class="select-primary"
                      v-for="data in BoxSizeData"
                      :key="data.id"
                      :label="'ขนาด '+data.name"
                      :value="data.name"
                    >
                    </el-option>
                  </el-select>
                    <div style="font-size: 10px;color:red"> * ค่าบริการกล่อง {{BoxPrice}} บาท</div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">น้ำหนัก</label>
                <div class="col-sm-9">
                  <base-input
                   :disabled="check"
                    name="weight"
                    v-validate="'required'"
                    :error="getError('weight')"
                    placeholder="0.00"
                    v-model="Weight"></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">ค่าบริการเพิ่มเติม</label>
                <div class="col-sm-9">
                  <base-input
                   :disabled="check"
                    name="service"
                    :error="getError('service')"
                    placeholder="0.00"
                    v-model="ServiceCost"></base-input>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label">ข้อมูลเพิ่มเติม</label>
                <div class="col-sm-9">
                  <!--<base-input  :disabled="check" placeholder="หมายเหตุ" v-model="Remark"></base-input>-->
                   <base-textarea :disabled="check" placeholder="ข้อมูลเพิ่มเติม"
                     name="ข้อมูลเพิ่มเติม" v-model="Remark"/>

                </div>
              </div>


                 <!---deduct_packing-->


              <div class="row"  v-if="trackingMode === true">
                <label class="col-sm-3 col-form-label">รวมเป็นค่าใช้จ่ายในการบรรจุ</label>
                <div class="col-sm-9">
                  <base-input readonly placeholder="รวมเป็นค่าใช้จ่ายในการบรรจุ" v-model="deduct_packing"   ></base-input>
                </div>
              </div>

               <!---deduct_logistic-->

              <div class="row"  v-if="trackingMode === true">
                <label class="col-sm-3 col-form-label">ค่าใช้จ่ายในการขนส่ง</label>
                <div class="col-sm-9">
                  <base-input readonly placeholder="ค่าใช้จ่ายในการขนส่ง" v-model="deduct_logistic"   ></base-input>
                </div>
              </div>

               <!---total-->


              <div class="row"  v-if="trackingMode === true">
                <label class="col-sm-3 col-form-label">รวมใช้จ่ายทั้งหมด</label>
                <div class="col-sm-9">
                  <base-input readonly placeholder="รวมใช้จ่ายทั้งหมด" v-model="totalPrice"   ></base-input>
                </div>
              </div>




              <div class="row">

                <div class="col-md-12 d-flex justify-content-center flex-wrap">
                  <base-button class="mt-auto" native-type="button" type="success" v-on:click="SaveOrder"
                               v-if="CHECKSUBMIT">
                    <i class="tim-icons icon-check-2"></i> ยืนยัน
                  </base-button> &nbsp;
                  <base-button class="mt-auto" native-type="button" type="info" v-on:click="Print" v-if="CHECK">
                    <i class="tim-icons icon-paper"></i> พิมพ์
                  </base-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

    </div>
    <div class="row mt-lg-5">
      <div class="col-md-6">

      </div>
    </div>
   <div class="row mt-5" v-show="false">
      <div id="sender_info">
        <table style="margin-left: 25px;max-width: 430px">
          <tr>
            <td  style="text-align:center;">
              <div>
                 <div style="font-size: 30px;font-weight: bold">
                  {{Receiver.SendType}}
                </div>

              </div>
            </td>


          </tr>
          <tr>
            <td style="text-align:center;">
              <br>
              <div style="font-size: 12px;">Mooi Order No.</div>
              <VueBarcode v-bind:value="barcodePrint" height="30" fontSize="12">
                rendering fails.
              </VueBarcode>
            </td>
          </tr>
          <tr>
            <td style="font-size: 12px;font-weight: bold">ชื่อที่อยู่ผู้ส่ง/Sender</td>
          </tr>
          <tr>
            <td style="font-size: 12px;"> {{ this.Sender.CustomerName }} เบอร์ติดต่อ{{this.Sender.CustomerMobile}}</td>
          </tr>
          <tr>
            <td style="font-size: 12px;">ที่อยู่ {{this.Sender.CustomerAddress}} </td>
          </tr>
          <tr>
            <td style="font-size: 12px;font-weight: bold; text-align:right;"><br>ชื่อที่อยู่ผู้รับ/Address</td>
          </tr>
          <tr>
            <td style="font-size: 12px; text-align:right;"> {{ Receiver.Name }} เบอร์ติดต่อ {{ Receiver.Mobile }}</td>
          </tr>
          <tr>
            <td style="font-size: 12px; text-align:right;">ที่อยู่ {{ Receiver.Address }} {{ Receiver.Zipcode }}</td>
          </tr>
           <tr>
            <td style="font-size: 12px"><br>จัดส่งโดย {{ Receiver.SendType }}</td>
          </tr>
          <tr  v-if="this.Receiver.ReceiverCOD">
            <td style="font-size: 12px;padding-bottom: 5px">ยอดเก็บเงินปลายทาง (COD)  {{ this.formatPrice(Receiver.ReceiverCOD) }} บาท </td>
          </tr>

          <tr>
            <td>
                <table style="border-collapse: collapse;">
                  <tr>
                    <th style="font-size: 12px;width: 90px;border: 1px solid black">รหัสสินค้า</th>
                    <th style="font-size: 12px;width: 250px;border: 1px solid black">ชื่อสินค้า</th>
                    <th style="font-size: 12px;width: 70px;border: 1px solid black">จำนวน</th>
                  </tr>
                    <tr  v-for="column in queriedData" v-bind:key="column.BarCode">
                      <td style="font-size: 12px;width: 90px;border: 1px solid black; text-align: center;">{{column.BarCode}}</td>
                      <td style="font-size: 12px; padding-left:5px;width: 250px; border: 1px solid black">{{column.ProductName}}</td>
                      <td style="font-size: 12px;width: 70px;border: 1px solid black; text-align: center;">{{column.Amount}}</td>
                    </tr>
                </table>
            </td>
          </tr>






          <tr v-if="this.Receiver.Ref1">
            <td style="text-align:center;">
              <div style="font-size: 12px;"><br>Ref1 </div>
              <VueBarcode v-bind:value="this.Receiver.Ref1" height="30"  fontSize="12">
                rendering fails.
              </VueBarcode>
            </td>
          </tr>
          <tr v-if="this.Receiver.Ref2">
            <td style="text-align:center;">
             <div style="font-size: 12px;">Ref2 </div>
              <VueBarcode v-bind:value="this.Receiver.Ref2" height="30"  fontSize="12">
                rendering fails.
              </VueBarcode>
            </td>
          </tr>

        </table>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import zipcode from '@/helpers/zipcode.json'
import VueBarcode from "vue-barcode";
import moment from 'moment'
import BaseTextarea from "@/components/Inputs/BaseTexarea";



export default {
  components: {
    BasePagination,
    BaseTextarea,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio,
    VueBarcode,
  },
  computed: {
      ...mapState('product', ['productList']),
    ...mapState('users', ['user', 'status', 'itemData', 'statusItem', 'stockData']),
    ...mapState('orders', ['orderDetail', 'orderHead', 'orderTransactionItem' , 'orderStatus', 'saveOrderStatus'  ,'boxList' , 'orderTransaction' , 'zipList']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      check: false,
      Status:"",
      readOnlyMode: false,
      Scanned: false,
      CHECK: false,
      CHECKSUBMIT: false,
      ShowConfirm: false,
      barcodePrint: this.$route.params.orderNo,
      barcodeValue: "",
      loading: false,
      orderNo: "",
      CustomerID: "",
      customerFullName: "",
      LastRunning: 1,
      ItemSelect: "",
      zipcodeList: zipcode,
      Amount: 0,
      preAddData: {},
      stockItems: {},
      ServiceCost: "0",
      Weight: "",
      Tracking:"",
      Remark: "",
     SendType: [
        {ID: 1, Name: "ไปรษณีย์ EMS"},
        {ID: 2, Name: "ไปรษณีย์ ลงทะเบียน"},
        {ID: 3, Name: "ไปรษณีย์ COD"},
        {ID: 4, Name: "Kerry"},
        {ID: 5, Name: "Kerry COD"},
        {ID: 6, Name: "Kerry Same Day"},
        {ID: 8, Name: "Flash Express"},
        {ID: 13, Name: "Flash COD"},
        {ID: 7, Name: "แมสเซนเจอร์"},
        {ID: 9, Name: "Lazada"},
        {ID: 10, Name: "Shopee"},
        {ID: 11, Name: "SCG"},
        {ID: 12, Name: "J&T"},
        {ID: 14, Name: "Best Exress"},
        {ID: 15, Name: "ORANGE EXPRESS"},
        {ID: 16, Name: "NRF Go to laos"},
        {ID: 17, Name: "Tiktok"},
        {ID: 18, Name: "J&T COD"},
        {ID: 19, Name: "Kerry Central"},
      ],
      BoxSizeData: [
        {Name: "S", Size: "30 x 100 x 30 ซม./ cm."},
        {Name: "M", Size: "31 x 36 x 13 ซม./ cm."},
        {Name: "L", Size: "31 x 36 x 26 ซม./ cm."},
        {Name: "X", Size: "55 x 100 x 55 ซม./ cm."},
        {Name: "XL", Size: "40 x 45 x 35 ซม./ cm."},
        {Name: "XXL", Size: "45 x 55 x 40 ซม./ cm."},
      ],
      BoxSize: "",
      Receiver: {
        Name: "",
        Mobile: "",
        Address: "",
        Zipcode: "",
        COD: "",
        SendType: "",
        Ref1:"",
        Ref2:""
      },
      Sender: {
        CustomerName:"",
        CustomerNo: '',
        CustomerMobile: "",
        CustomerAddress: "",
        CustomerZipcode: "",
        productName: '',
        price: 0,
        amount: 0,
        expireDate: ""
      },
      pagination: {
        perPage: 110,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      tableColumns: [

        {
          prop: 'BarCode',
          label: 'รหัสสินค้า',
          minWidth: 100
        },
        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 150
        },

        {
          prop: 'Amount',
          label: 'จำนวน',
          minWidth: 80
        },
        {
          prop: 'AmountPick',
          label: 'จำนวนที่หยิบ',
          minWidth: 100
        }
      ],

      tableColumnsPrint: [

        {
          prop: 'BarCode',
          label: 'รหัสสินค้า',
          minWidth: 100
        },
        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 150
        },

        {
          prop: 'Amount',
          label: 'จำนวน',
          minWidth: 80
        },

      ],
      deduct_packing:0,
      deduct_logistic:0,
      totalPrice:0,
      tableData: [],
      tableDataTransactionItem: [],
      tableDataOrder: [],
      searchedData: [],
      fuseSearch: null,
       trackingMode:false,
       BoxPrice:0,
       BoxPrice2:0,
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }

     this.getProduct()
    this.orderNo = this.$route.params.orderNo
    this.getOrderDetail(this.orderNo)
    this.getOrderTransaction(this.orderNo)

    this.getBoxList()
    this.getZipCode()
  },
  methods: {
     ...mapActions('account', ['logout']),
    ...mapActions('product', ['getProduct']),
    ...mapActions('orders', ['getOrderDetail', 'pickingItem', 'confirmPacked', 'clearStateStatus' , 'getBox' , 'getOrderTransaction', 'getZipCode']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
     format_date(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
          }
    },
     onChange(event) {
            var cost = 0 ;
             for (let i = 0; i < this.BoxSizeData.length; i++) {
               if(this.BoxSizeData[i].name === event){
                 cost = this.BoxSizeData[i].price
               }
             }
             console.log("AAAAAAAA")
               console.log(event)
                 console.log(cost)
            this.BoxPrice = cost;
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
     getBoxList() {
      this.getBox()
    },
    async Picked() {


      const data = {
        OrderNo: this.orderNo,
        Barcode: this.barcodeValue,
        Amount: "1"
      }

      console.log(data)
      this.pickingItem(data)

    },
    filter(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false
        }, 200)
      } else {
        this.clients = []
      }
    },
    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    setItemSelect(data) {
      this.preAddData = this.stockItems.filter(elem => {
        if (elem.id === data) return elem;
      })
    },
    getStock() {
      this.getMeStock()
    },
    Print() {
      this.CHECKSUBMIT = true;
      if (this.Status === 'COMPLETED') {
          this.CHECKSUBMIT = false
      }
      this.$htmlToPaper('sender_info')
    },
    validate() {
      if (this.BoxSize === "" || this.BoxSize === null) {
        swal("กรุณาเลือกขนาดกล่อง")
        return false
      }
      return true
    },
    async SaveOrder() {
      if (!this.validate()) {
        return false;
      }
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {

        if(this.ServiceCost === "") {
          this.ServiceCost = "0";
        }
        var cost = 0 ;
         for (let i = 0; i < this.BoxSizeData.length; i++) {
           if(this.BoxSizeData[i].name === this.BoxSize){
             cost = this.BoxSizeData[i].price
           }
         }



        const data = {
          order_no: this.orderNo,
          box: this.BoxSize,
          weight: this.Weight,
          service_cost: (parseFloat(this.ServiceCost)+ parseFloat(cost)) + "",
          remark: this.Remark,
          items: this.tableData,
          tracking: this.tracking
        }


         swal({
            title: 'คุณแน่ใจไหม?',
            text: `คุณต้องการบันทึกการบรรจุหรือไม่`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Yes it!',
            buttonsStyling: false
          }).then(result => {
            if (result.value) {
              this.confirmPacked(data)
            }
          });



      }
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.barcode}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    zipList(val) {
      // this.zipcodeList = val.items.data;
      this.zipcodeList = [];
      for (let i = 0; i < val.items.data.length; i++) {
        this.zipcodeList.push({ID: val.items.data[i] , Value: val.items.data[i]})
      }

    },
     boxList(val) {
      this.BoxSizeData = val.items.data;


        var cost = 0 ;
        for (let i = 0; i < this.BoxSizeData.length; i++) {

          if(this.BoxSizeData[i].name == this.BoxSize){
            cost = this.BoxSizeData[i].price
          }
        }
        this.BoxPrice = cost;
        if (this.Status === 'COMPLETED' || this.Status === 'TRACKED'  || this.Status === "PACKED") {
          this.ServiceCost = parseFloat(this.ServiceCost) - parseFloat(cost)
          this.ServiceCost = this.formatPrice(this.ServiceCost)
        }


    },

    saveOrderStatus(val) {
      this.$router.push('/picking/list')
      // if (val.message === "success") {
      //   swal({
      //     title: `บันทึกข้อมูลสำเร็จ!`,
      //     text: 'บันทึกข้อมูลสำเร็จ!',
      //     buttonsStyling: false,
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     type: 'success'
      //   }).then(_ => {
      //     this.$router.push('/picking/list')
      //   })
      // } else if (val.message === "error") {
      //   swal({
      //     title: `บันทึกข้อมูลไม่สำเร็จ!`,
      //     text: 'กรุณาลองใหม่!'
      //   })
      // }
    },
     orderTransaction(val){
      if(val.item.items !== null) {
         this.tableDataOrder = val.item.items
        for (let i = 0; i < this.tableDataOrder.length; i++) {
          this.tableDataOrder[i].time = this.format_date(this.tableDataOrder[i].time)
        }
      }

    },
     orderTransactionItem(val) {
      this.deduct_logistic = 0;
      this.deduct_packing = 0;
      this.totalPrice = 0;

      if(val.item !== null) {
        this.tableDataTransactionItem = val.item
        for (let i = 0; i <  this.tableDataTransactionItem.length; i++) {

          if(this.tableDataTransactionItem[i].TransactionType == 'deduct_packing') {
            this.deduct_packing = this.deduct_packing + val.item[i].Amount;
          }

          if(this.tableDataTransactionItem[i].TransactionType == 'deduct_logistic') {
            this.deduct_logistic = this.deduct_logistic +val.item[i].Amount;
          }

          this.totalPrice = this.totalPrice + val.item[i].Amount;

        }


           this.totalPrice = this.formatPrice(this.totalPrice)
           this.deduct_logistic = this.formatPrice(this.deduct_logistic)
           this.deduct_packing = this.formatPrice(this.deduct_packing)
      }
    },
     productList(val) {
       console.log("AAAAAA")
        this.stockItems = val.items.items
         console.log("AAAAAA")
        for (let i = 0; i < this.tableData.length; i++) {
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableData[i].BarCode === this.stockItems[ii].SKU){
                this.tableData[i].Image =  this.stockItems[ii].Image
              }
          }

        }
    },
    orderDetail(val) {
      this.tableData = val.items
      for (let i = 0; i < this.tableData.length; i++) {
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableData[i].BarCode === this.stockItems[ii].SKU){
                this.tableData[i].Image =  this.stockItems[ii].Image
              }
          }

        }

        console.log("AAAAAA")
    },
    orderHead(val) {


      this.Sender.CustomerName = val.item.SenderName
      this.Sender.CustomerNo = val.item.Sender
      this.Sender.CustomerZipcode =val.item.SenderZipcode
      this.Sender.CustomerMobile = val.item.SenderTel
      this.Sender.CustomerAddress = val.item.SenderAddress
      this.Receiver.Ref1 = val.item.Ref1
      this.Receiver.Ref2 = val.item.Ref2
      this.Receiver.Name = val.item.ReceiverName
      this.Receiver.Mobile = val.item.ReceiverTel
      this.Receiver.Address = val.item.ReceiverAddress
      this.Receiver.Zipcode = val.item.ReceiverZipcode
      this.Receiver.COD = val.item.ReceiverCOD
      this.Receiver.SendType = val.item.SendType
      this.Receiver.ReceiverCOD = val.item.ReceiverCOD

      this.Receiver.COD = this.formatPrice(this.Receiver.COD)

      this.Status = val.item.Status ;
      if (val.item.Status === "PICKING") {
        this.ShowConfirm = true
      }
       if (val.item.Status === 'COMPLETED' || val.item.Status === 'TRACKED'  || val.item.Status === "PACKED") {
        this.Scanned = true
        this.Remark = val.item.Remark
        this.Weight = val.item.Weight
        this.BoxSize = val.item.BoxSize
        this.ServiceCost = val.item.ServiceCost
        this.Tracking = val.item.tracking_number
        this.readOnlyMode = true

        this.ServiceCost = this.formatPrice(this.ServiceCost)
        this.Weight = this.formatPrice(this.Weight)

      }

      if (val.item.Status === 'COMPLETED' || val.item.Status === 'TRACKED'  || val.item.Status === "PACKED") {
         this.trackingMode = true
          this.check = true


            var cost = 0 ;
            for (let i = 0; i < this.BoxSizeData.length; i++) {

              if(this.BoxSizeData[i].Name == this.BoxSize){
                cost = this.BoxSizeData[i].Price
              }
            }
            this.BoxPrice = cost;
            if (this.Status === 'COMPLETED' || this.Status === 'TRACKED'  || this.Status === "PACKED") {
              this.ServiceCost = parseFloat(this.ServiceCost) - parseFloat(cost)
            }


            this.ServiceCost = this.formatPrice(this.ServiceCost)

      }


      let num = 0;
      let num2 = 0;
      for (let i = 0; i < this.tableData.length; i++) {
         num = num + this.tableData[i].AmountPick;
         num2 = num2 + this.tableData[i].Amount;
      }

      if(num == num2) {
        this.CHECK = true
        this.CHECKSUBMIT = false

      }

       if (val.item.Status === 'COMPLETED') {
          this.CHECKSUBMIT = false
       }



    },
    orderStatus(val) {
      if (val.message === "success1") {
        // swal({
        //   title: `บันทึกข้อมูลสำเร็จ!`,
        //   text: 'บันทึกข้อมูลสำเร็จ!',
        //   buttonsStyling: false,
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   type: 'success'
        // }).then(_ => {
        //   this.$router.push('/order/list')
        // })
        this.$router.push('/order/list')
      } else if (val.message === "error") {
        swal({
          title: `บันทึกข้อมูลไม่สำเร็จ!`,
          text: 'ไม่พบสินค้าหรือจำนวนหยิบเกินกำหนด!'
        })
      }
      this.barcodeValue = ""
      this.Scanned = true


      let num = 0;
      let num2 = 0;
      for (let i = 0; i < this.tableData.length; i++) {
         num = num + this.tableData[i].AmountPick;
         num2 = num2 + this.tableData[i].Amount;
      }

      if(num == num2) {
        this.CHECK = true
        this.CHECKSUBMIT = false

      }

       if (this.Status === 'COMPLETED') {
          this.CHECKSUBMIT = false
       }


      this.clearStateStatus()
    },
    statusItem(val) {
      if (val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล สินค้า!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
        const zeroPad = (num) => String(num).padStart(4, '0')
        this.BarCodePrefix = this.BarCodeSKU
        this.BarCode = this.BarCodeSKU + zeroPad(1)
      }
    },
    status(val) {
      if (val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }
    },
    user(val) {
      console.log(val.ID)
      this.CustomerID = val.ID
      this.customerFullName = val.firstName + ' ' + val.lastName
    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.el-select {
  width: 100%;
}
.search-input {
  width: 200px;
}


</style>
