<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มรายการกล่อง</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
          <!-- <h4 class="card-title">Form Receive Item</h4> -->
        </template>
        <div>
          <form method="get" action="/" class="form-horizontal">


            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อกล่อง</label>
              <div class="col-sm-10">
                <base-input placeholder="ชื่อกล่อง" v-model="Name"></base-input>
              </div>
            </div>


           <div class="row">
              <label class="col-sm-2 col-form-label">ราคา</label>
              <div class="col-sm-9">
                <base-input placeholder="ราคา" type="number" v-model="Price"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">บาท</label>
            </div>




            <div class="row">
              <label class="col-sm-2 col-form-label">ต้นทุน</label>
              <div class="col-sm-9">
                <base-input placeholder="ราคา" type="number" v-model="Cost"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">บาท</label>
            </div>

             <div class="row">
              <label class="col-sm-2 col-form-label">จำนวน</label>
              <div class="col-sm-9">
                <base-input placeholder="จำนวน" type="number" v-model="Amount"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">กล่อง</label>
            </div>


            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">บันทึกข้อมูล</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'
import {
  ImageUpload,
} from 'src/components/index';

export default {
  components: {
    ImageUpload,
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('orders', ['orderStatus' ]),

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      max:3,
      images: {
        regular: null,
        avatar: null
      },
      preAddData: {},
      preAddData2: {},
      userItems: {},
      stockItems: {},
      CustomerID:"",
      customerFullName:"",
      BarCodePrefix:"",
      BarCode:"",
      BarCodeSKU:"",
      Cost:"0",
      Amount:"0",
      Name:"",
      Price:"0",
      LastRunning:1,
      Model: {
        CustomerNo: '',
        RecNo: '',
        productName: '',
        price: '0',
        amount: '0',
        expireDate: moment().add(1,'year')
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [ 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      searchedData: [],
      fuseSearch: null
    };
  },
  created () {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

  },
  methods: {
    ...mapActions('account', ['logout']),
     ...mapActions('orders', [ 'addBox']),

    AddItem() {
       const data = {
         "name": this.Name ,
        "cost":  this.Cost,
        "amount":  this.Amount,
        "price":  this.Price
       }

        this.addBox(data)
    },

  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    orderStatus(val) {
      if (val.message === "success") {
        // swal({
        //   title: `บันทึกข้อมูลสำเร็จ!`,
        //   text: 'บันทึกข้อมูลสำเร็จ!',
        //   buttonsStyling: false,
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   type: 'success'
        // }).then(_ => {
        //   this.$router.push('/order/list')
        // })
        this.$router.push('/box/list')
      } else if (val.message === "error") {
        swal({
          title: `บันทึกข้อมูลไม่สำเร็จ!`,
          text: 'กรุณาตรวจสอบข้อมูล'
        })
      }

    },
    recStatus(val){
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/receive/list')
        })
      }

    },
    statusItem(val) {
      const zeroPad = (num) => String(num).padStart(6, '0')
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล สินค้า!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
        this.BarCodePrefix = this.Model.CustomerNo+this.BarCodeSKU
        this.BarCode = this.Model.CustomerNo+this.BarCodeSKU+'-'+zeroPad(1)
      }
    },
    status(val){
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }
    },
    user(val){
      console.log(val.ID)
      this.CustomerID = val.ID
      this.customerFullName = val.firstName + ' '+ val.lastName
     // console.log(ID)
      //this.getLastItemCode({ID})
    },
    itemData(val){
      const zeroPad = (num) => String(num).padStart(6, '0')
      this.BarCodePrefix = val.code
      this.BarCode = val.code+'-'+zeroPad(val.no)
      this.LastRunning = val.no
    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>

.el-select {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.pagination-select,
.search-input {
  width: 200px;
}
</style>
