<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มพื้นที่จัดเก็บ</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
          <!-- <h4 class="card-title">Form Receive Item</h4> -->
        </template>
        <div>
          <form method="get" action="/" class="form-horizontal">


            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อพื้นที่จัดเก็บ</label>
              <div class="col-sm-10">
                <base-input placeholder="ชื่อพื้นที่จัดเก็บ" v-model="BarCodeSKU"></base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">กว้าง</label>
              <div class="col-sm-9">
                <base-input placeholder="กว้าง" type="number" v-model="Model.productName"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">เมตร</label>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">ยาว</label>
              <div class="col-sm-9">
                <base-input placeholder="ยาว" type="number" v-model="Model.productName"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">เมตร</label>
            </div>

             <div class="row">
              <label class="col-sm-2 col-form-label">สูง</label>
              <div class="col-sm-9">
                <base-input placeholder="สูง" type="number" v-model="Model.productName"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">เมตร</label>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">ราคา</label>
              <div class="col-sm-9">
                <base-input placeholder="ราคา" type="number" v-model="Model.productName"></base-input>
              </div>
               <label class="col-sm-1 col-form-label">บาท</label>
            </div>


            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">บันทึกข้อมูล</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['user','status','itemData','statusItem','userData','stockData']),
    ...mapState('receive',['recStatus']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      max:3,
      preAddData: {},
      preAddData2: {},
      userItems: {},
      stockItems: {},
      CustomerID:"",
      customerFullName:"",
      BarCodePrefix:"",
      BarCode:"",
      BarCodeSKU:"",
      LastRunning:1,
      Model: {
        CustomerNo: '',
        RecNo: '',
        productName: '',
        price: '0',
        amount: '0',
        expireDate: moment().add(1,'year')
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }
    this.getUser()

  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['getMeStock','getUserByCode','getLastItemCode','getItemCustomerByCode','getAll2']),
    ...mapActions('receive',['SaveReceiveData','clearStateStatus']),
    searchCustomer(){
      const code = this.Model.CustomerNo
      this.getUserByCode({code})
    },
    getUser() {
      this.getAll2()
    },
    getStock() {
      this.getMeStock()
    },
    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    setItemSelect(data) {
      console.log(data);
      this.preAddData = this.userItems.filter(elem => {
        if (elem.ID === data) return elem;
      })
      console.log(this.preAddData );
      this.customerFullName = this.preAddData[0].firstName + ' ' +  this.preAddData[0].lastName;
      this.getStock()
    },
    dispatch2(e) {
      this.$emit('input', e)
      this.selected2 = e
    },

    setItemSelect2(data) {
      console.log(data);
      this.preAddData2 = this.stockItems.filter(elem => {
        if (elem.id === data) return elem;
      })
      console.log(this.preAddData2 );
      // this.customerFullName = this.preAddData2[0].firstName + ' ' +  this.preAddData2[0].lastName;


    },
    searchITEM(){
      const CustomerNo = this.CustomerID
      const ItemNo = this.BarCodeSKU
      this.getItemCustomerByCode({CustomerNo, ItemNo})
    },
    SaveReceive(){
      const data = {
        ReceiveNo:this.Model.RecNo,
        CustomerID:this.CustomerID,
        data:this.tableData
      }
      console.log(data)
      this.SaveReceiveData({data})
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.barcode}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    AddItem() {
     // const zeroPad = (num) => String(num).padStart(4, '0')
      const barcode = this.BarCodePrefix
      const date = moment(this.Model.expireDate).format('YYYY-MM-DD')
      this.tableData.push({
        id: this.total + 1,
        barcode: barcode,
        amount: this.Model.amount,
        price : this.Model.price,
        productName: this.Model.productName,
        expireDate: date
      });
      this.LastRunning = this.LastRunning+1
      this.Model.amount = "0"
      this.Model.price = "0"
      this.Model.barcode = ""
      this.Model.productName = ""
    },
    zeroPad(num){
      console.log("num",num)
      String(num).padStart(4, '0')
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    userData(val) {
      console.log(val.items.items);
      this.userItems = val.items.items
    },
    stockData(val) {
        console.log(val.items);
      this.stockItems = val.items
    },
    recStatus(val){
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/receive/list')
        })
      }

    },
    statusItem(val) {
      const zeroPad = (num) => String(num).padStart(6, '0')
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล สินค้า!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
        this.BarCodePrefix = this.Model.CustomerNo+this.BarCodeSKU
        this.BarCode = this.Model.CustomerNo+this.BarCodeSKU+'-'+zeroPad(1)
      }
    },
    status(val){
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }
    },
    user(val){
      console.log(val.ID)
      this.CustomerID = val.ID
      this.customerFullName = val.firstName + ' '+ val.lastName
     // console.log(ID)
      //this.getLastItemCode({ID})
    },
    itemData(val){
      const zeroPad = (num) => String(num).padStart(6, '0')
      this.BarCodePrefix = val.code
      this.BarCode = val.code+'-'+zeroPad(val.no)
      this.LastRunning = val.no
    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>

.el-select {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.pagination-select,
.search-input {
  width: 200px;
}
</style>
