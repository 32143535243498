<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">แก้ไขข้อมูลส่วนตัว</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="sendData">

            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('First Name')"
                  v-model="model.firstName"
                  name="First Name"
                  placeholder="ชื่อ"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">นามสกุล</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Last Name')"
                  v-model="model.lastName"
                  name="Last Name"
                  placeholder="นามสกุล"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">อีเมล์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|email'"
                  :error="getError('email')"
                  v-model="model.email"
                  name="email"
                  placeholder="อีเมล์"
                  addon-left-icon="tim-icons icon-email-85"
                >
                </base-input>
              </div>
            </div>
             <div class="row">
              <label class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Phone Number')"
                  v-model="model.phoneNumber"
                  name="phoneNumber"
                  placeholder="เบอร์โทรศัพท์"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">ที่อยู่</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('ที่อยู่')"
                  v-model="model.address"
                  name="ที่อยู่"
                  placeholder="ที่อยู่"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ตำบล</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('ตำบล')"
                  v-model="model.district"
                  name="ตำบล"
                  placeholder="ตำบล"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">อำเภอ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('อำเภอ')"
                  v-model="model.amphure"
                  name="อำเภอ"
                  placeholder="อำเภอ"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">จังหวัด</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('จังหวัด')"
                  v-model="model.province"
                  name="จังหวัด"
                  placeholder="จังหวัด"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">รหัสไปรษณีย์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('รหัสไปรษณีย์')"
                  v-model="model.zipCode"
                  name="รหัสไปรษณีษ์"
                  placeholder="รหัสไปรษณีษ์"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="submit" type="success" >บันทึก</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import { userService } from '@/services';

import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['AddUserStatus']),
  },
  data() {
    return {
      model: {
        ID: 0,
        userName: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        address: '',
        nationality: '',
        province: '',
        amphure: '',
        district: '',
        zipCode: '',
        idNumber: '',
        AcceptTerm: false,
        dimension_height: 0,
        dimension_width: 0,

      }
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['UpdateMe']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        const user = this.model
        this.UpdateMe({user})
      }
    }
  },
  mounted() {
    // Fuse search initialization.
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }



    userService.Me().then(users =>{
        this.model = users
      })
  },

  watch: {
    AddUserStatus(val){
      console.log(val);
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/dashboard')
        })
      }  else {
         swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
