<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มเจ้าหน้าที่</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="sendData">>
            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อผู้ใช้งาน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('username')"
                  v-model="model.userName"
                  name="username"
                  placeholder="ชื่อผู้ใช้งาน"
                  addon-left-icon="tim-icons icon-email-85"
                />
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">รหัสผ่าน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|min:5'"
                  :error="getError('password')"
                  v-model="model.password"
                  name="password"
                  type="password"
                  placeholder="รหัสผ่าน"
                  addon-left-icon="tim-icons icon-lock-circle"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อ</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('First Name')"
                  v-model="model.firstName"
                  name="First Name"
                  placeholder="ชื่อ"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">นามสกุล</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Last Name')"
                  v-model="model.lastName"
                  name="Last Name"
                  placeholder="นามสกุล"
                  addon-left-icon="tim-icons icon-single-02"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">อีเมล์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|email'"
                  :error="getError('email')"
                  v-model="model.email"
                  name="email"
                  placeholder="อีเมล์"
                  addon-left-icon="tim-icons icon-email-85"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required'"
                  :error="getError('Phone Number')"
                  v-model="model.phoneNumber"
                  name="phoneNumber"
                  placeholder="เบอร์โทรศัพท์"
                  addon-left-icon="tim-icons icon-badge"
                >
                </base-input>
              </div>
            </div>

            <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Role</label>
                <div class="col-sm-10">
                  <el-select
                    ref="gender"
                    name="gender"
                    v-validate="'required'"
                    :error="getError('gender')"
                    class="select-primary mb-8"
                    placeholder="---- กรุณาเลือก ----"
                    v-model="model.gender">
                   <el-option
                      class="select-primary"
                      v-for="data in roleData"
                      :key="data.Value"
                      :label="data.Name"
                      :value="data.Value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>



            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="submit" type="success" >บันทึก</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['AddUserStatus']),
  },
  data() {
    return {
      model: {
        userName: '',
        password: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        gender: '',
      },
       roleData: [
          {Name: "Admin Director", Value : "admin_director"},
          {Name: "Admin Order", Value : "admin_order"},
          {Name: "Admin Cargo", Value : "admin_cargo"},
          {Name: "Packing", Value : "packing"},
          {Name: "Packing +", Value : "packing_plus"},
        ],
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['AddUserStaff']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();
      this.model.dimension_height = parseFloat(this.model.dimension_height).toFixed(2)
      this.model.dimension_width = parseFloat(this.model.dimension_width).toFixed(2)
      if (isValidForm) {
        const user = this.model
        this.AddUserStaff({user })
      }
    }
  },
  mounted() {
    // Fuse search initialization.
  },
   created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

  },
  watch: {
    AddUserStatus(val){
      console.log(val);
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/staff/list')
        })
      } else {
         swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
      // this.clearStateStatus()
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.el-select {
  width: 100%;
}
</style>
