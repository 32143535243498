<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการรอขึ้นรถ</h2>
    </div>
    <div class="row mt-5">

      <modal :show.sync="modals.notice" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Order</h4>

        <div>
          <form method="get" action="/" class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">น้ำหนัก</label>
              <div class="col-sm-10">
                <base-input placeholder="น้ำหนัก" type="number" v-model="weightModal"></base-input>
              </div>
            </div>

          </form>
        </div>

        <template slot="footer">
          <base-button class="mt-3 ml-3 mb-3" v-on:click="onSubmit">ตกลง</base-button>
          <base-button class="mt-3 mr-3 mb-3" type="danger" v-on:click="modals.notice = false">ปิด
          </base-button>
        </template>
      </modal>


      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-3">
                <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-6">
              </div>

              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>
            </div>
            <el-table :data="queriedData">
              <el-table-column :min-width="135" align="left" label="การกระทำ">
                <div slot-scope="props">


                  <el-tooltip content="ทำรายการขึ้นรถ" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handModal(props.$index, props.row)" class="edit btn-link" type="primary"
                      size="sm" icon>
                      <i class="tim-icons icon-bus-front-12"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="ดูรายละเอียด" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleView(props.$index, props.row)" class="edit btn-link" type="warning"
                      size="sm" icon>
                      <i class="tim-icons icon-bag-16"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>



              <el-table-column label="วันที่" :min-width="180">
                <div slot-scope="props">
                  <div>{{ props.row.date_order }}</div>
                </div>
              </el-table-column>


              <el-table-column label="รหัสลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>{{ props.row.sender_code }}</div>
                </div>
              </el-table-column>


              <el-table-column label="เลขรายการสั่งซื้อ" :min-width="150">
                <div slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleView(props.$index, props.row)">
                    {{ (props.row.orderNo) }}
                  </div>

                </div>
              </el-table-column>



              <el-table-column label="ชื่อลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>

                    <el-tooltip :content="returnSvclinesValue(props.row.customer_name)" effect="dark" :open-delay="300"
                      placement="top">
                      <div>{{ props.row.customer_name.substring(0, 15) + ".." }}</div>
                    </el-tooltip>




                  </div>
                </div>
              </el-table-column>







              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>

              <el-table-column :min-width="150" align="center" label="รูปแบบการส่ง">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>

                </div>
              </el-table-column>

              <!--<el-table-column label="Tracking Number" :min-width="200">
                <div slot-scope="props">
                  <div>{{props.row.tracking_number}}</div>
                </div>
              </el-table-column>--->

              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status === 'NEW'">NEW</div>
                  <div class="badge badge-info" v-if="props.row.status === 'PICKING'">PICKING</div>
                  <div class="badge badge-success" v-if="props.row.status === 'COMPLETED'">COMPLETED</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'TRACKED'">TRACKED</div>
                  <div v-if="props.row.status === 'TRACKED'">{{ props.row.tracking_number }}</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'PACKED'">PACKED</div>
                  <div class="badge badge-danger" v-if="props.row.status === 'CANCEL'">CANCEL</div>


                </div>
              </el-table-column>


            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import { Modal, BaseAlert } from 'src/components';
import moment from 'moment'



export default {
  components: {
    Modal,
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin', 'saveOrderStatus']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      orderNo: "",
      weightModal: "",
      modals: {
        notice: false,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      tableColumns: [
        {
          prop: 'receiver_name',
          label: 'ชื่อผู้รับ',
          minWidth: 200
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }
    this.adminPackedOrders()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['adminPackedOrders', 'confirmPackingOrder2']),
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
      }
    },
    returnSvclinesValue(data) {
      return data;
    },

    handleView(index, row) {
      // this.$router.push({name:'Order Car View',params:{orderNo:row.orderNo}})
      this.$router.push('/picking-car/detail/' + row.orderNo)
    },
    handModal(index, row) {
      // this.$router.push({name:'Order Car View',params:{orderNo:row.orderNo}})
      this.orderNo = row.orderNo;
      let user = JSON.parse(localStorage.getItem('users'));
      //  this.modals.notice = true;
      const data = {
        order_no: this.orderNo,
        staff_id: user.ID,

      }
      console.log(data)
      swal({
        title: 'คุณแน่ใจไหม?',
        text: `คุณต้องการนำ order นี้ขึ้นรถใช่ไหม?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.confirmPackingOrder2(data)
        }
      });
    },
    async onSubmit() {
      if (this.weightModal <= 0 || this.weightModal == undefined || this.weightModal == "") {
        swal({
          title: 'ไม่สามารถทำรายการได้',
          text: `กรุณากรอกน้ำหนัก`,
          type: 'error',
        });
        return
      }


      let user = JSON.parse(localStorage.getItem('users'));


      const data = {
        order_no: this.orderNo,
        staff_id: user.ID,

      }
      console.log(data)
      swal({
        title: 'คุณแน่ใจไหม?',
        text: `คุณต้องการนำ order นี้ขึ้นรถใช่ไหม?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.confirmPackingOrder2(data)
        }
      });
    },
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      threshold: 0.3
    });
  },
  watch: {
    saveOrderStatus(val) {
      console.log("AAAA")
      // this.$router.push('/picking-car/list')
      // window.location.reload()
      //  this.modals.notice = false;
      //   this.tableData = [];
      //  this.adminPackedOrders()

      swal({
        title: 'ทำรายการขึ้นรถเสร็จสิ้น',
        text: ``,
        type: 'success',
        showLoaderOnConfirm: true,
        focusConfirm: true,
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          window.location.reload()
        }
      });


    },
    orderListAdmin(val) {
      this.tableData = val.items.items
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
