<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-warning">
            <i class="tim-icons icon-wifi"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Marketing</h3>
            <p class="description">
              We've created the marketing campaign of the website. It was a very
              interesting collaboration.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="tim-icons icon-triangle-right-17"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Fulfilment</h3>
            <p class="description">
              Professional Order Fulfillment Warehouse For eCommerce, Customizable and Flexible. Our Order Management
              System is designed for the SEA market with full connectivity (API) to Marketplace, Website, Social
              commerce, etc. We are trusted by Global brands/Public co, guaranteed with reviews and national
              <rewards class=""></rewards>
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="tim-icons icon-trophy"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Store your item</h3>
            <p class="description">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
              of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-7 mr-auto">
        <form @submit.prevent="sendData">
          <card class="card-register card-white">
            <template slot="header">
              <img class="card-img" src="img/card-primary.png" alt="Card image"/>
              <h4 class="card-title" style="margin-left: 10px;color: #403103">Register</h4>
            </template>
            <base-input
              v-validate="'required'"
              :error="getError('username')"
              v-model="model.userName"
              name="username"
              placeholder="Username"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
            <base-input
              v-validate="'required|min:5'"
              :error="getError('password')"
              v-model="model.password"
              name="password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('First Name')"
              v-model="model.firstName"
              name="First Name"
              placeholder="First Name"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('Last Name')"
              v-model="model.lastName"
              name="Last Name"
              placeholder="Last Name"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              v-validate="'required|email'"
              :error="getError('email')"
              v-model="model.email"
              name="email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
            <base-input
              type="number"
              v-validate="'required'"
              :error="getError('dimension height')"
              v-model="model.dimension_height"
              name="dimension height"
              placeholder="ขนาดความสูง"
              addon-left-icon="tim-icons icon-app"
            >
            </base-input>
            <base-input
              type="number"
              v-validate="'required'"
              :error="getError('dimension width')"
              v-model="model.dimension_width"
              name="dimension width"
              placeholder="ขนาดความกว้าง"
              addon-left-icon="tim-icons icon-app"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('ที่อยู่')"
              v-model="model.address"
              name="ที่อยู่"
              placeholder="ที่อยู่"
              addon-left-icon="tim-icons icon-badge"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('ตำบล')"
              v-model="model.district"
              name="ตำบล"
              placeholder="ตำบล"
              addon-left-icon="tim-icons icon-badge"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('อำเภอ')"
              v-model="model.amphure"
              name="อำเภอ"
              placeholder="อำเภอ"
              addon-left-icon="tim-icons icon-badge"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('จังหวัด')"
              v-model="model.province"
              name="จังหวัด"
              placeholder="จังหวัด"
              addon-left-icon="tim-icons icon-badge"
            >
            </base-input>
            <base-input
              v-validate="'required'"
              :error="getError('รหัสไปรษณีษ์')"
              v-model="model.zipcode"
              name="รหัสไปรษณีษ์"
              placeholder="รหัสไปรษณีษ์"
              addon-left-icon="tim-icons icon-badge"
            >
            </base-input>
            <base-checkbox class="text-left" v-model="model.AcceptTerm">
              I agree to the <a href="#something">terms and conditions</a>.
            </base-checkbox>

            <base-button native-type="submit" slot="footer" type="primary" round block size="lg">
              สมัครสมาชิก
            </base-button>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {BaseCheckbox} from 'src/components';
import {mapActions} from "vuex";


export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      model: {
        userName: 'test03',
        password: '12345',
        email: 'test@mail.com',
        phoneNumber: '0909999999',
        firstName: 'test0001',
        lastName: 'test0001',
        address: '111/111 test',
        nationality: 'Thai',
        province: 'กรุงเทพมหานคร',
        amphure: 'มีนบุรี',
        district: 'มีนบุรี',
        zipcode: '10510',
        idNumber: '1411111100111',
        AcceptTerm: false,
        dimension_height: 100,
        dimension_width: 100,

      }
    };
  },
  methods: {
    ...mapActions('account',['register']),
    select (address) {
      this.model.district = address.district
      this.model.amphoe = address.amphoe
      this.model.province = address.province
      this.model.zipcode = address.zipcode
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        const user = this.model
        this.register({user })
      }
    }
  }
};
</script>

