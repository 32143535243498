<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เพิ่มรายการนำสินค้าออกจากคลัง</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
        </template>
        <div>
          <form method="get" action="/" class="form-horizontal">
            <div class="row">
              <label class="col-md-2 col-form-label">รหัสลูกค้า</label>
              <div class="col-md-10">
                    <el-select
                       class="select-primary"
                        @input="dispatch"
                        size="large"
                        :filterable="true"
                        :disabled="disabled"
                        placeholder="---- กรุณาเลือก ----"
                        v-model="Model.CustomerNo"
                        @change="setItemSelect"
                      >
                      <el-option
                        class="select-primary"
                        v-for="item in userItems"
                        :key="item.ID"
                        :label="item.cus_id +' : '+item.firstName+'  '+item.lastName"
                        :value="item.ID"
                      >
                      </el-option>
                    </el-select>


              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label">ชื่อลูกค้า</label>
              <div class="col-md-10">
                <base-input placeholder="None" v-model="customerFullName" readonly></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label">SKU</label>
              <div class="col-md-10">
                    <el-select
                       class="select-primary"
                      @input="dispatch2"
                      size="large"
                      :filterable="true"
                      placeholder="---- กรุณาเลือก ----"
                      v-model="BarCodeSKU"
                      @change="setItemSelect2"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in stockItems"
                        :key="item.ID"
                        :label="'SKU : '+item.SKU +' Product Name : '+item.ProductName +' จำนวน  : '+item.Amount"
                        :value="item.ID"
                      >
                      </el-option>
                    </el-select>

              </div>

            </div>
            <div class="row">
              <label class="col-md-2 col-form-label">ชื่อสินค้า</label>
              <div class="col-md-10">
                <base-input placeholder="Product Name" v-model="Model.productName" readonly></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label">จำนวน</label>
              <div class="col-md-10">
                <base-input type="number" placeholder="จำนวน" v-model="Model.amount"></base-input>
              </div>
            </div>
            <div class="row">
                    <label class="col-md-2 col-form-label">เหตุผล</label>
                    <div class="col-md-10">
                      <el-select
                        class="select-primary"
                        placeholder="---- กรุณาเลือก ----"
                        @input="dispatch"
                        size="large"
                        :filterable="true"
                        v-model="Note">
                        <el-option
                          class="select-primary"
                          v-for="data in NoteType"
                          :key="data.ID"
                          :label="data.Name"
                          :value="data.Name"
                        >
                        </el-option>
                      </el-select>
                    </div>
            </div>
            <div class="row">
                <label class="col-sm-2 col-form-label">หมายเหตุ</label>
                <div class="col-sm-10 mb-2">
                    <base-textarea placeholder="หมายเหตุ" v-model="NoteDetail"/>
                </div>
            </div>

            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="AddItem">ADD</base-button>
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Item List</h4>
          <div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="การกระทำ">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>

           <div class="row mt-3">
              <div class="col-md-12"  style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="SaveReceive">บันทึก</base-button>
              </div>
          </div>

        </card>
      </div>
    </div>


  </div>
</template>



<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'
import { Modal, BaseAlert } from 'src/components';
import BaseTextarea from "@/components/Inputs/BaseTexarea";



export default {
  components: {
    BaseTextarea,
    BasePagination,
    Modal,
    BaseAlert,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['user','status','itemData','statusItem','userData','stockData','itemData']),
    ...mapState('receive',['recStatus']),
    ...mapState('product',['productList' , 'AddProductStatus']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      max:4,
      idUser:"",
      sku:"",
      Note:"",
      NoteDetail: "",
      NoteType: [
        {ID :"1" , Name : "นำเข้าเกินจำนวน"},
        {ID :"2" , Name : "ส่งคืนสินค้าชำรุด/หมดอายุ"},
        {ID :"3" , Name : "นำส่งคืนลูกค้า"},
        {ID :"4" , Name : "อื่นๆ"},

      ],
      disabled:false,
      preAddData: {},
      preAddData2: {},
      preAddData3: {},
      userItems: {},
      stockItems: {},
      CustomerID:"",
      customerFullName:"",
      customerFullName2:"",
      BarCodePrefix:"",
      BarCode:"",
      BarCodeSKU:"",
      BarCodeSKULast:"",
      isNewSKU: false,
      LastRunning:1,
      cus_id:null,
      ModelProduct: {
        customer_id: '',
        sku: '',
        product_name: '',
      },
      Model:{},
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      tableColumns: [
        {
          prop: 'barcode',
          label: 'SKU',
          minWidth: 200
        },
        {
          prop: 'productName',
          label: 'Product Name',
          minWidth: 200
        },
        {
          prop: 'amount',
          label: 'จำนวน',
          minWidth: 100
        },
        {
          prop: 'reason',
          label: 'เหตุผล',
          minWidth: 200
        },
        {
          prop: 'remark',
          label: 'หมายเหตุ',
          minWidth: 200
        },

      ],

      tableData: [],
      searchedData: [],
      fuseSearch: null,
      modals: {
        classic: false,
        notice: false,
        mini: false
      },
      disable: false
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }
    this.getUser()
  },
  methods: {
     ...mapActions('account', ['logout']),
    ...mapActions('users',['getMeStock','getUserByCode','getLastItemCode','getItemCustomerByCode','getAll2' , 'getLastItem']),
    ...mapActions('receive',['SaveReceiveOutData','clearStateStatus']),
    ...mapActions('product',['getAdminProduct' , 'AddProduct' , "getProductByCustomerID"]),
    searchCustomer(){
      const code = this.Model.CustomerNo
      this.getUserByCode({code})
    },
    NewSKU(){
      this.isNewSKU = true
      this.modals.notice = false
    },
    getUser() {
      this.getAll2()
    },

    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    setItemSelect(data) {
      console.log("ID",data);
      this.stockItems = [];
      this.BarCode = ''
      this.Model.productName = '';
      this.getAdminProduct({data})
      this.preAddData = this.userItems.filter(elem => {
        if (elem.ID === data) return elem;
      })
      console.log(this.preAddData);
      this.customerFullName = this.preAddData[0].firstName + ' ' +  this.preAddData[0].lastName;
      this.idUser = data;
      this.sku = "";
      this.BarCodeSKU = "";
      this.getProductByCustomerID(data)
      if(this.sku != "") {
         const data = {
           cus : this.idUser ,
           sku : this.sku ,
         }
        this.getLastItem(data)
      }


    },
    getProductByCustomerIDData(){
       this.getProductByCustomerID(this.idUser)
    },
    dispatch2(e) {
      this.$emit('input', e)
      this.selected2 = e
    },

    setItemSelect2(data) {
      console.log(data);
      console.log(this.stockItems);
      this.preAddData2 = this.stockItems.filter(elem => {
        if (elem.ID === data) return elem;
      })
       console.log(this.preAddData2);
      this.BarCode = this.preAddData2[0].SKU;
      this.Model.productName = this.preAddData2[0].ProductName;
      console.log( this.preAddData2[0].SKU)
       console.log(this.BarCode)
      this.sku = this.preAddData2[0].SKU;
        console.log( this.sku )


       if(this.sku != "") {
         const data = {
           cus : this.idUser ,
           sku : this.sku ,
         }
        // this.getLastItem(data)
      }
    },

    setItemSelect3(data) {
      console.log("ID",data);
      this.preAddData3 = this.userItems.filter(elem => {
        if (elem.ID === data) return elem;
      })
      console.log(this.preAddData3);
      this.customerFullName2 = this.preAddData3[0].firstName + ' ' +  this.preAddData3[0].lastName;

    },

    SaveReceive(){
      const data = {
        // ReceiveNo:this.Model.RecNo,
        customer_id:this.Model.CustomerNo,
        data:this.tableData
      }
      console.log(data)
      this.SaveReceiveOutData(data)

    },

    AddItem() {
     // const zeroPad = (num) => String(num).padStart(4, '0')
     if(this.BarCode.length == 0){
         swal({
            title: 'ไม่สามารถเพิ่มข้อมูล',
            text: `กรุณากรอก SKU`,
            type: 'error',
          });
        return
     }


    if(this.Note.length == 0){
         swal({
            title: 'ไม่สามารถเพิ่มข้อมูล',
            text: `กรุณากรอกเหตุผล`,
            type: 'error',
          });
        return
     }



      if(this.Model.amount <= 0 || this.Model.amount == undefined) {
         swal({
            title: 'ไม่สามารถเพิ่มข้อมูล',
            text: `กรุณากรอกจำนวน`,
            type: 'error',
          });
        return
     }

    if(this.Model.amount >   this.preAddData2[0].Amount) {
         swal({
            title: 'ไม่สามารถเพิ่มข้อมูล',
            text: `จำนวนเอาออกมากกว่าจำวนสินค้าที่มี`,
            type: 'error',
          });
        return
     }



    const barcode = this.BarCode
    var res = barcode.split("-");

    let check =  false;
    let num = 0;
    for (let i = 0; i < this.tableData.length; i++) {
       if(this.tableData[i].barcode ==  res[0]){
         check = true;
         num = num + parseInt(this.tableData[i].amount);
       }
    }

    num = num + parseInt(this.Model.amount);
    console.log(num)


    if(check == true) {

        if(num >   this.preAddData2[0].Amount) {
            swal({
                title: 'ไม่สามารถเพิ่มข้อมูล',
                text: `จำนวนเอาออกมากกว่าจำวนสินค้าที่มี`,
                type: 'error',
              });
            return
        }
    }


      this.tableData.push({
            id: this.total + 1,
            barcode: res[0],
            amount: this.Model.amount,
            productName: this.Model.productName,
            reason: this.Note,
            remark: this.NoteDetail,
          });



      this.LastRunning = this.LastRunning+1
      this.Model.amount = "0"
      this.Model.barcode = ""
      this.Note = ""
      this.NoteDetail = ""
      this.Model.productName = ""
      this.BarCode = ""
      this.BarCodeSKU = ""
      this.disabled = true;

    },
    zeroPad(num){
      console.log("num",num)
      String(num).padStart(4, '0')
    },
     async CreateProduct() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        const product = this.ModelProduct
        this.AddProduct({product })
      }
    },
     handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.productName}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      console.log(row)
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );

      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }

  },
  mounted() {
    // Fuse search initialization.

  },
  watch: {
    productList(val) {
      this.stockItems = val.items.items
    },
    userData(val) {
        console.log(val)
      this.userItems = val.items.items
    },

    itemData(val) {
      console.log(val)
      const zeroPad = (num) => String(num).padStart(6, '0')
      // console.log(this.preAddData[0])
      // this.BarCodeSKULast = val
      this.BarCode  =  val.code + '-' + zeroPad(val.no);
    },

    stockData(val) {
      this.stockItems = val.items
    },
    recStatus(val){
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/goods-out/list')
        })
      }

    },
    statusItem(val) {
      const zeroPad = (num) => String(num).padStart(6, '0')
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล สินค้า!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
        this.BarCodePrefix = this.Model.CustomerNo+this.BarCodeSKU
        this.BarCode = this.Model.CustomerNo+this.BarCodeSKU+'-'+zeroPad(1)
      }
    },
    status(val){
      if(val.message !== "success") {
        swal({
          title: `ไม่พบข้อมูล!`,
          text: 'ไม่พบข้อมูล!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'warning'
        })
      }
    },
    user(val){
      console.log(val.ID)
      this.CustomerID = val.ID
      this.customerFullName = val.firstName + ' '+ val.lastName
     // console.log(ID)
      //this.getLastItemCode({ID})
    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.0
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },

    AddProductStatus(val){
      console.log(val);
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            // this.$router.push('/product/list')
            this.modals.notice = false;
            this.getProductByCustomerIDData()
        })
      } else {
        swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
    },
  }
};
</script>
<style>

.el-select {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.pagination-select,
.search-input {
  width: 200px;
}
</style>
