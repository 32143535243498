<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการรับสินค้าเข้าคลัง</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">
                <router-link to="/receive/create" >
                  <base-button type="success" class="animation-on-hover">รับเข้าสินค้า</base-button>&nbsp;
                </router-link>
              </div>
            </div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >

              <div class="col-md-3">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              </div>

              <div class="col-md-6">
              </div>

              <div class="col-md-3">
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>

              </base-input>
              </div>
            </div>
            <el-table :data="queriedData">
              <el-table-column :min-width="100" align="left" label="การกระทำ">
                  <div slot-scope="props">
                    <el-tooltip
                    content="พิมพ์บาร์โค้ด"
                    effect="dark"
                    :open-delay="300"
                    placement="top">
                      <base-button
                        @click.native="handlePrint(props.$index, props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-paper"></i>
                      </base-button>
                    </el-tooltip>


                      <el-tooltip
                      content="รายละเอียด"
                      effect="dark"
                      :open-delay="300"
                      placement="top">
                        <base-button
                          @click.native="handleView(props.$index, props.row)"
                          class="edit btn-link"
                          type="info"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-zoom-split"></i>
                        </base-button>
                      </el-tooltip>


                  </div>
                </el-table-column>


              <el-table-column :min-width="160" align="left" label="เลขที่ใบรับสินค้า" prop="receive_no" >
                  <template slot-scope="props">
                    <div  style="cursor: pointer;" v-on:click="handleView(props.$index, props.row)">
                    {{(props.row.receive_no)}}
                    </div>

                  </template>
                </el-table-column>


              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>


                <el-table-column :min-width="160" align="left" label="วันที่รับสินค้า" prop="created_at" >
                  <template slot-scope="props">

                  {{(props.row.created_date)}}
                  </template>
                </el-table-column>

                <el-table-column :min-width="120" align="left" label="บันทึกข้อมูลโดย">
                  <div slot-scope="props">
                  {{props.row.created_by}}
                  </div>
                </el-table-column>

                <el-table-column :min-width="140" align="left" label="จำนวนรายการสินค้า">
                  <div slot-scope="props">
                  {{props.row.total}}
                  </div>
                </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div></div
></template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import receiveList from './receive'
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import router from "@/routes/router";
import {mapActions, mapState} from "vuex";
import moment from 'moment'



export default {
  components: {
    BasePagination,
    moment,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('receive',['recList']),


    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {

      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
         console.log(this.total)
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['receive_no' , 'created_date' ,'created_by' , 'customer_id' , 'customer_name'],
      tableColumns: [

         {
          prop: 'customer_id',
          label: 'รหัสลูกค้า',
          minWidth: 150
        },
         {
          prop: 'customer_name',
          label: 'ชื่อลูกค้า',
          minWidth: 150
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
     ...mapActions('account', ['logout']),
    ...mapActions('receive',['getAll']),
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
          }
    },
    handleResults (r) {
      this.results = r
    },
    handleConfirm(index,row) {
      swal({
        title: 'คุณแน่ใจว่าต้องการรับรายการนี้ ?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handlePrint(index,row){
      // this.$router.push({name:'ปริ้นรายการรับสินค้า',params:{recNo:row.receive_no}})
      this.$router.push('/receive/print/' + row.receive_no)
    },
    handleView(index,row){
      this.$router.push('/receive/view/' + row.receive_no)
    },

    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
      router.push('/receive/edit/'+row.id)
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    getReceiveList(){
      this.getAll()
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: [
        'receive_no' , 'created_date' ,'created_by' , 'customer_id' , 'customer_name'],
      threshold: 0.3,

    });
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    this.getReceiveList()
  },
  watch: {
    recList(val){
      console.log("RecList",val.items)
      console.log("RecList",val.items.items)
      this.tableData = val.items.items

      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].created_date = this.format_date(this.tableData[i].created_date)
      }

    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.0,

          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
