<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">เปลี่ยนรหัสผ่าน</h2>
    </div>
    <div class="row mt-5">
      <card>

        <div>
          <form class="form-horizontal" @submit.prevent="sendData">

            <div class="row">
              <label class="col-sm-2 col-form-label">รหัสผ่านใหม่</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|min:5'"
                  :error="getError('passwordNew')"
                  v-model="model.passwordNew"
                  name="passwordNew"
                  type="password"
                  placeholder="รหัสผ่านใหม่"
                  addon-left-icon="tim-icons icon-lock-circle"
                >
                </base-input>
              </div>
            </div>
             <div class="row">
              <label class="col-sm-2 col-form-label">ยืนยันรหัสผ่าน</label>
              <div class="col-sm-10">
                <base-input
                  v-validate="'required|min:5'"
                  :error="getError('passwordConfirm')"
                  v-model="model.passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  placeholder="ยืนยันรหัสผ่าน"
                  addon-left-icon="tim-icons icon-lock-circle"
                >
                </base-input>
              </div>
            </div>
             <div class="row">
              <div class="col-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="submit" type="success" >บันทึก</base-button>
              </div>
            </div>

          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";
import moment from 'moment'

export default {
  components: {
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  computed: {
    ...mapState('users',['AddUserStatus']),
  },
  data() {
    return {
      model: {
        passwordConfirm: '',
        passwordNew: '',

      }
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users',['UpdatePassword']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async sendData() {
      let isValidForm = await this.$validator.validateAll();


      if(this.model.passwordNew != this.model.passwordConfirm) {
        swal({
            title: 'ไม่สามารถบันทึก',
            text: `รหัสผ่านไม่ตรงกัน`,
            type: 'error',
          });
        return
      }
      this.model.dimension_height = parseFloat(this.model.dimension_height).toFixed(2)
      this.model.dimension_width = parseFloat(this.model.dimension_width).toFixed(2)
      if (isValidForm) {
        let user = JSON.parse(localStorage.getItem('users'));
        const password = {
          "ID":  user.ID,
          "userName": user.userName,
          "password": this.model.passwordNew,
        }
        this.UpdatePassword(password)
      }
    }
  },
  mounted() {
    // Fuse search initialization.
  },
  created () {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

  },
  watch: {
    AddUserStatus(val){
      console.log(val);
      if(val.message === "success") {
        swal({
          title: `บันทึกข้อมูลสำเร็จ!`,
          text: 'บันทึกข้อมูลสำเร็จ!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_=>{
            this.$router.push('/dashboard')
        })
      } else {
         swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
      }
      // this.clearStateStatus()
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
