<template>
<div>
  <div class="wrapper wrapper-full-page">



    <center v-if="isDesktop == true">

     <div  style="max-width:1024px;">
         <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
           <div class="mb-3 mt-3">
                <router-link to="/login" >
                  <base-button type="success" class="animation-on-hover">เข้าสู่ระบบ</base-button>&nbsp;
                </router-link>
              </div>


              <div class="mb-3 mt-3">
                <router-link to="/mail" >
                  <base-button type="success" class="animation-on-hover" >ส่งเมล</base-button>&nbsp;
                </router-link>
              </div>

        </div>
        <img src="img/01PC.jpg" />
        <img src="img/02PC.jpg" />
        <img src="img/03PC.jpg" />
        <img src="img/04PC.jpg" />
        <img src="img/05PC.jpg" />
        <img src="img/06PC.jpg" />
        <img src="img/07PC.jpg" />
        <img src="img/08PC.jpg" />
        <img src="img/09PC.jpg" />
        <img src="img/10PC.jpg" />
        <img src="img/11PC.jpg" />
        <!--<img src="img/12PC.jpg" />
        <img src="img/13PC.jpg" width="100%" />-->


     </div>
    </center>
     <div v-if="isTablet == true">
      <div class="col-md-12">
              <div class="mb-3 mt-3">
                <router-link to="/login" >
                  <base-button type="success" class="animation-on-hover">เข้าสู่ระบบ</base-button>&nbsp;
                </router-link>
              </div>

               <div class="mb-3 mt-3">
                <router-link to="/mail" >
                  <base-button type="success" class="animation-on-hover" >ส่งเมล</base-button>&nbsp;
                </router-link>
              </div>
        </div>
        <img src="img/1Tablet.jpg" />
        <img src="img/2Tablet.jpg" />
        <img src="img/3Tablet.jpg" />
        <img src="img/4Tablet.jpg" />
        <img src="img/5Tablet.jpg" />
        <img src="img/6Tablet.jpg" />
        <img src="img/7Tablet.jpg" />
        <img src="img/8Tablet.jpg" />
        <img src="img/9Tablet.jpg" />
        <img src="img/10Tablet.jpg" />
        <img src="img/11Tablet.jpg" />
         <!--<img src="img/12Tablet.jpg" />-->

    </div>

     <div v-if="isMoblie == true">
      <div class="col-md-12">
              <div class="mb-3 mt-3">
                <router-link to="/login" >
                  <base-button type="success" class="animation-on-hover">เข้าสู่ระบบ</base-button>&nbsp;
                </router-link>
              </div>

               <div class="mb-3 mt-3">
                <router-link to="/mail" >
                  <base-button type="success" class="animation-on-hover"  >ส่งเมล</base-button>&nbsp;
                </router-link>
              </div>
       </div>
        <img src="img/1Mobile.jpg" />
        <img src="img/2Mobile.jpg" />
        <img src="img/3Mobile.jpg" />
        <img src="img/4Mobile.jpg" />
        <img src="img/5Mobile.jpg" />
        <img src="img/6Mobile.jpg" />
        <img src="img/7Mobile.jpg" />
        <img src="img/8Mobile.jpg" />
        <img src="img/9Mobile.jpg" />
        <img src="img/10Mobile.jpg" />
        <img src="img/11Mobile.jpg" />
         <!--<img src="img/12Mobile.jpg" />-->

    </div>
  </div>
</div>

</template>
<script>
import { mapState, mapActions } from 'vuex'
// Vue.use(VueMq, {
//   breakpoints: {
//     mobile: 450,
//     tablet: 900,
//     laptop: 1250,
//     desktop: Infinity,
//   }
// })


export default {

  data() {
    return {
      model: {
        // isMoblie: false,
        // isTablet: false,
        // isDesktop: false,
        email: '',
        password: '',
        subscribe: true,


      }
    };
  },
  computed: {
    ...mapState('account', ['status'])

  },
  created() {


    console.log(screen.width)
    if(screen.width <= 480){
      this.isMoblie = true
    } else  if(screen.width <= 768){
      this.isTablet = true
    } else {
       this.isDesktop = true
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async handleSubmit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        //await router.push('dashboard')
        console.log("Login")
        const username = this.model.email;
        const password = this.model.password
        await this.login({ username, password})
      }
    },
    Print(){
      console.log("AAAAA")
    },
    isMoblie() {
        if( screen.width <= 480 ) {
            return true;
        }

    },
     isTablet() {
        if( screen.width > 480 && screen.width <= 768 ) {
            return true;
        }

    },
     isDesktop() {
        if( screen.width >768 ) {
            return true;
        }

    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

</style>
