<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">ค่าใช้จ่าย</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div class="row">

            <label class="col-sm-1 col-form-label" style="text-align: right">วันที่</label>
            <div class="col-sm-2 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Day">
                <el-option class="select-primary" v-for="data in DayType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>



            <label class="col-sm-1 col-form-label" style="text-align: right">เดือน</label>
            <div class="col-sm-3 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Mount">
                <el-option class="select-primary" v-for="data in MountType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>
            <label class="col-sm-1 col-form-label" style="text-align: right">ปี</label>
            <div class="col-sm-2 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Year">
                <el-option class="select-primary" v-for="data in YearType" :key="data.ID" :label="data.Name"
                  :value="data.Name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-2 mb-2">
              <base-button class="mt-auto" native-type="button" type="success" v-on:click="onSearch">ค้นหา</base-button>
            </div>
          </div>
        </card>



        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-12 mb-3">
                <base-button class="mt-auto" native-type="button" type="success" style="float: right;"
                  v-on:click="onExport">Export</base-button>
              </div>


              <div class="col-md-3">
                <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-6">
              </div>


              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>


            </div>

            <el-table :data="queriedData">


              <el-table-column :min-width="120" align="left" label="วันที่" prop="created_time">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.created_time }}</div>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" align="left" label="เลขรายการสั่งซื้อ" prop="order_no">
                <template slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleView(props.$index, props.row)">
                    {{ (props.row.SummaryItem.order_no) }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column :min-width="80" align="left" label="ค่ากล่อง" prop="packing_amount">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.packing_amount }}</div>
                </template>
              </el-table-column>

              <el-table-column :min-width="80" align="left" label="ค่าบริการ" prop="service_charge">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.top_up_amount }}</div>
                </template>
              </el-table-column>


              <el-table-column :min-width="80" align="left" label="ค่าส่งสินค้า" prop="logistic_amount">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.logistic_amount }}</div>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" align="left" label="รวม" prop="total_amount">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.total_amount }}</div>
                </template>
              </el-table-column>

              <el-table-column :min-width="120" align="left" label="ขนส่ง" prop="send_type">
                <template slot-scope="props">

                  <div v-if="props.row.SummaryItem.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.SummaryItem.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>


                </template>
              </el-table-column>



              <el-table-column :min-width="100" align="left" label="Tracking no" prop="tracking_no">
                <template slot-scope="props">
                  <div>{{ props.row.SummaryItem.track_no }}</div>
                </template>
              </el-table-column>


            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import moment from 'moment'

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['SummaryItem.created_time', 'SummaryItem.order_no'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      MountType: [
        { ID: 1, Name: "มกราคม" },
        { ID: 2, Name: "กุมภาพันธ์" },
        { ID: 3, Name: "มีนาคม" },
        { ID: 4, Name: "เมษายน" },
        { ID: 5, Name: "พฤษภาคม" },
        { ID: 6, Name: "มิถุนายน" },
        { ID: 7, Name: "กรกฎาคม" },
        { ID: 8, Name: "สิงหาคม" },
        { ID: 9, Name: "กันยายน" },
        { ID: 10, Name: "ตุลาคม" },
        { ID: 11, Name: "พฤศจิกายน" },
        { ID: 12, Name: "ธันวาคม" },
      ],
      YearType: [
        { ID: 2021, Name: "2021" },
        { ID: 2022, Name: "2022" },
        { ID: 2023, Name: "2023" },
        { ID: 2024, Name: "2024" },
        { ID: 2025, Name: "2025" },
      ],
      DayType: [
        { ID: 0, Name: "ทั้งหมด" },
        { ID: 1, Name: "1" },
        { ID: 2, Name: "2" },
        { ID: 3, Name: "3" },
        { ID: 4, Name: "4" },
        { ID: 5, Name: "5" },
        { ID: 6, Name: "6" },
        { ID: 7, Name: "7" },
        { ID: 8, Name: "8" },
        { ID: 9, Name: "9" },
        { ID: 10, Name: "10" },
        { ID: 11, Name: "11" },
        { ID: 12, Name: "12" },
        { ID: 13, Name: "13" },
        { ID: 14, Name: "14" },
        { ID: 15, Name: "15" },
        { ID: 16, Name: "16" },
        { ID: 17, Name: "17" },
        { ID: 18, Name: "18" },
        { ID: 19, Name: "19" },
        { ID: 20, Name: "20" },
        { ID: 21, Name: "21" },
        { ID: 22, Name: "22" },
        { ID: 23, Name: "23" },
        { ID: 24, Name: "24" },
        { ID: 25, Name: "25" },
        { ID: 26, Name: "26" },
        { ID: 27, Name: "27" },
        { ID: 28, Name: "28" },
        { ID: 29, Name: "29" },
        { ID: 30, Name: "30" },
        { ID: 31, Name: "31" },
      ],
      Mount: null,
      Year: 2024,
      Day: null,
    };
  },
  computed: {
    ...mapState('users', ['stockData', 'transaction']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    const date = new Date();
    this.Mount = date.getMonth() + 1;


    this.Day = 0
    var day = ""
    if (this.Day == 0) {
      day = ""
    } else {
      day = this.Day
    }


    const users = localStorage.getItem('users');
    const userData = JSON.parse(users)

    console.log("AAAAAA")
    console.log(userData.ID)
    console.log("AAAAAA")


    const data = {
      Day: day,
      Mount: this.Mount,
      Year: this.Year,
      UserID: userData.ID
    }


    this.getTransactionSummaryUser(data)
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users', ['getTransactionSummaryUser', 'getReportSummaryTransactionExport']),
    toCustomerID(id) {
      const zeroPad = (num) => String(num).padStart(3, '0')
      return 'C' + zeroPad(id)
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatPrice(value) {
      let val = ((value) / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    handleView(index, row) {
      this.$router.push({ name: 'Order View', params: { orderNo: row.SummaryItem.order_no } })
    },
    onSearch() {
      this.searchQuery = ""
      this.tableData = []

      var day = ""
      if (this.Day == 0) {
        day = ""
      } else {
        day = this.Day
      }

      const users = localStorage.getItem('users');
      const userData = JSON.parse(users)

      const data = {
        Day: day,
        Mount: this.Mount,
        Year: this.Year,
        UserID: userData.ID
      }
      this.getTransactionSummaryUser(data)
    },
    onExport() {
      var day = ""
      if (this.Day == 0) {
        day = ""
      } else {
        day = this.Day
      }

      const users = localStorage.getItem('users');
      const userData = JSON.parse(users)
      const data = {
        Day: day,
        Mount: this.Mount,
        Year: this.Year,
        UserID: userData.ID
      }
      this.getReportSummaryTransactionExport(data)
    },
    getStock() {
      this.getTransactionSummaryUser()
    },
    dispatch(e) {
      this.$emit('input', e)
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['CreatedAt'],
      threshold: 0.3
    });
  },
  watch: {
    transaction(val) {
      console.log("DATA", val.items)
      this.tableData = val.items.data
      if (this.tableData === null) {
        this.tableData = []
      }
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].SummaryItem.created_time = this.format_date(this.tableData[i].SummaryItem.created_time)
        this.tableData[i].SummaryItem.packing_amount = this.formatPrice(this.tableData[i].SummaryItem.packing_amount)
        this.tableData[i].SummaryItem.logistic_amount = this.formatPrice(this.tableData[i].SummaryItem.logistic_amount)
        this.tableData[i].SummaryItem.total_amount = this.formatPrice(this.tableData[i].SummaryItem.total_amount)
        this.tableData[i].SummaryItem.top_up_amount = this.formatPrice(this.tableData[i].SummaryItem.top_up_amount)
      }
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.0
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>


