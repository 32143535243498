<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">อัพเดทการส่งสินค้า</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">


        <file-upload @change="onImageChange" select-text="เลือกไฟล์"  />

        <card card-body-classes="table-full-width" class="mt-3">

          <div>
           <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >

              <!--<div class="col-md-3">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              </div>

              <div class="col-md-6">
              </div>

              <div class="col-md-3">
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
              </div>-->
            </div>

            <el-table :data="queriedData">

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>




            </el-table>
          </div>

        </card>
      </div>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";
import {
  ImageUpload,
} from 'src/components/index';
import {
  FileUpload,
} from 'src/components/index';


export default {
  components: {
    FileUpload,
    ImageUpload,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('users', ['stockData']),
    ...mapState('orders',['uploadTrackList']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
        console.log(this.searchedData)
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      stockItems: {},
      searchQuery: '',
      propsToSearch: ['po', 'lot', 'barcode'],
      tableColumns: [
        {
          prop: 'order_no',
          label: 'Order No',
          minWidth: 150
        },
         {
          prop: 'message',
          label: 'Message',
          minWidth: 300
        },
        {
          prop: 'status',
          label: 'สถานะ',
          minWidth: 150
        },


      ],
      tableData: [],
      searchedData: [],
      imageBase64: null,
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    let user = JSON.parse(localStorage.getItem('users'));
    console.log(user)
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders',['uploadTrack']),
    onImageChange(file) {
        let user = JSON.parse(localStorage.getItem('users'));
        this.uploadTrack(file)
        // var reader = new FileReader()
        // reader.readAsDataURL(file)
        // reader.onload = ()=> {
        //   this.imageBase64 = reader.result;
        //   console.log(this.imageBase64)
        // }
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
     uploadTrackList(val) {
      console.log("stockData", val.item.items)
        // for (let i = 0; i < val.item.items.length; i++) {
        //    this.tableData.push(val.item.items[i])
        // }

         this.tableData = val.item.items


    },
     searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
     stockData(val) {
      console.log("stockData", val.items)
      this.tableData = val.items.items
    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
