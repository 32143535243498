<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการใบแจ้งหนี้</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">

          <div>
            <div
              class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >

              <div class="col-md-3">
                 <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
               <div class="col-md-6">
               </div>


               <div class="col-md-3">
                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
               </div>


            </div>

            <el-table :data="queriedData">

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>

              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div class="badge badge-success">ACTIVE</div> &nbsp;
                </div>
              </el-table-column>

               <el-table-column label="การกระทำ" :min-width="100">
                <div slot-scope="props">
                </div>
              </el-table-column>


            </el-table>
          </div>
          <div
            slot="footer"
            class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";



export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('users', ['stockData']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
        console.log(this.searchedData)
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      stockItems: {},
      searchQuery: '',
      propsToSearch: ['po', 'lot', 'barcode'],
      tableColumns: [
        {
          prop: 'Barcode',
          label: 'รหัส',
          minWidth: 120
        },
        {
          prop: 'ProductName',
          label: 'ร้านค้า',
          minWidth: 150
        },
        {
          prop: 'Amount',
          label: 'วันที่',
          minWidth: 100
        },

         {
          prop: 'Amount',
          label: 'ยอดหนี้',
          minWidth: 100
        },

        {
          prop: 'Amount',
          label: 'ยอดชำระ',
          minWidth: 100
        },

        {
          prop: 'Amount',
          label: 'ยอดค้าง',
          minWidth: 100
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }
     this.getStock()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users', ['getMeStockTabel']),
    getStock() {
      this.getMeStockTabel()
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['barcode', 'lot'],
      threshold: 0.3
    });
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
