<style>
.pagination-select,
.search-input {
  width: 200px;
}

.page-break {
  display: block;
  page-break-before: always;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}
</style>

<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการรอบรรจุ</h2>
    </div>


    <modal :show.sync="modals.notice" headerClasses="justify-content-center" type="mini">
      <h4 slot="header" class="title title-up">เลือกรายการ</h4>


      <table style="border-collapse: collapse;">
        <tr>
          <th style="font-size: 14px;width: 100px;border: 1px solid black;text-align: center;">เลือก</th>
          <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">เลขรายการสั่งซื้อ</th>
          <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">รหัส ชื่อลูกค้า</th>
          <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">ขนส่ง</th>
          <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">รหัส ชื่อ product</th>
          <th style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">จำนวน</th>

        </tr>
        <tr v-for="(column, index) in tableDataModal" v-bind:key="column.orderNo">
          <td style="font-size: 14px;width: 100px;border: 1px solid black;text-align: center;">
            <input type="checkbox" id="column.orderNo" name="column.orderNo" value="column.orderNo"
              :checked="column.checked" v-on:click="onCheckbox(column.orderNo, index)">
          </td>
          <td style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">
            {{ column.orderNo }}
          </td>
          <td style="font-size: 14px;width: 400px;border: 1px solid black;text-align: center;">

          </td>
          <td style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">

          </td>
          <td style="font-size: 14px;width: 300px;border: 1px solid black;text-align: center;">

          </td>
          <td style="font-size: 14px;width: 200px;border: 1px solid black;text-align: center;">

          </td>

        </tr>
      </table>



      <template slot="footer">
        <base-button class="mt-3 ml-3 mb-3" v-on:click="CreateProduct">ตกลง</base-button>
        <base-button class="mt-3 mr-3 mb-3" type="danger" v-on:click="modals.notice = false">ปิด
        </base-button>
      </template>
    </modal>


    <div class="row mt-5">
      <div class="col-md-12">
        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-3">
                <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-6">
              </div>

              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">
                <base-button type="success" class="animation-on-hover"
                  @click.native="openModal()">พิมพ์</base-button>&nbsp;
              </div>
            </div>
            <el-table :data="queriedData">

              <!-- <el-table-column label="เลือก" :min-width="100">
                <div slot-scope="props">
                <base-checkbox :checked="true" :name="props.row.date_order" :id="props.row.date_order"></base-checkbox>

                </div>
              </el-table-column> -->


              <el-table-column :min-width="140" align="left" label="การกระทำ">
                <div slot-scope="props">

                  <el-tooltip content="ทำรายการบรรจุ" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleView(props.$index, props.row)" class="edit btn-link" type="warning"
                      size="sm" icon>
                      <i class="tim-icons icon-bag-16"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="ลบ Order" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleDelete(props.$index, props.row)" class="edit btn-link" type="danger"
                      size="sm" icon>
                      <i class="tim-icons icon-trash-simple"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>

              <el-table-column label="ผู้ทำรายการ" :min-width="120">
                <div slot-scope="props">

                </div>
              </el-table-column>

              <el-table-column label="รหัสลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>{{ props.row.sender_code }}</div>
                </div>
              </el-table-column>


              <el-table-column label="เลขรายการสั่งซื้อ" :min-width="150">
                <div slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleView(props.$index, props.row)">
                    {{ (props.row.orderNo) }}
                  </div>

                </div>
              </el-table-column>



              <el-table-column label="ชื่อลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>

                    <el-tooltip :content="returnSvclinesValue(props.row.customer_name)" effect="dark" :open-delay="300"
                      placement="top">
                      <div>{{ props.row.customer_name.substring(0, 15) + ".." }}</div>
                    </el-tooltip>




                  </div>
                </div>
              </el-table-column>





              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>

              <el-table-column label="ขนส่ง" :min-width="100">
                <div slot-scope="props">

                </div>
              </el-table-column>

              <el-table-column :min-width="150" align="center" label="รูปแบบการส่ง">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>

                </div>
              </el-table-column>


              <!--<el-table-column label="Tracking Number" :min-width="200">
                <div slot-scope="props">
                  <div>{{props.row.tracking_number}}</div>
                </div>
              </el-table-column>--->



              <el-table-column label="สถานะ" :min-width="100">
                <div slot-scope="props">
                  <div class="badge badge-success" v-if="props.row.status === 'NEW'">NEW</div>
                  <div class="badge badge-info" v-if="props.row.status === 'PICKING'">PICKING</div>
                  <div class="badge badge-success" v-if="props.row.status === 'COMPLETED'">COMPLETED</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'TRACKED'">TRACKED</div>
                  <div v-if="props.row.status === 'TRACKED'">{{ props.row.tracking_number }}</div>
                  <div class="badge badge-warning" v-if="props.row.status === 'PACKED'">PACKED</div>
                  <div class="badge badge-danger" v-if="props.row.status === 'CANCEL'">CANCEL</div>
                </div>
              </el-table-column>


            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row mt-5" v-show="false">
      <div id="sender_info">
        <div v-for="(data, index) in tableDataSelect" v-bind:key="data">
          <table style="margin-left: 25px;max-width: 430px">
            <tr>
              <td style="text-align:center;">
                <br>
                <div style="font-size: 12px;">Mooi Order No.</div>
                <VueBarcode v-bind:value="tableDataSelect[index]" height="30" fontSize="12">
                  rendering fails.
                </VueBarcode>
              </td>
            </tr>
            <tr>
              <td style="font-size: 12px;font-weight: bold">ชื่อที่อยู่ผู้ส่ง/Sender</td>
            </tr>
            <tr>
              <td style="font-size: 12px;"> {{ dataPrintSender[index].Sender.CustomerName || '' }}
                เบอร์ติดต่อ{{ dataPrintSender[index].Sender.CustomerMobile || '' }}
              </td>
            </tr>
            <tr>
              <td style="font-size: 12px;">ที่อยู่ {{ dataPrintSender[index].Sender.CustomerAddress || '' }}</td>
            </tr>
            <tr>
              <td style="font-size: 12px;font-weight: bold; text-align:right;"><br>ชื่อที่อยู่ผู้รับ/Address</td>
            </tr>
            <tr>
              <td style="font-size: 12px; text-align:right;"> {{ dataPrintReceive[index].Receiver.Name || '' }}
                เบอร์ติดต่อ {{
                  dataPrintReceive[index].Receiver.Mobile
                }}
              </td>
            </tr>
            <tr>
              <td style="font-size: 12px; text-align:right;">ที่อยู่ {{ dataPrintReceive[index].Receiver.Address || '' }}
                {{ dataPrintReceive[index].Receiver.Zipcode || '' }}</td>
            </tr>
            <tr>
              <td style="font-size: 12px;">จัดส่งโดย {{ dataPrintReceive[index].Receiver.SendType }}</td>
            </tr>
            <tr v-if="dataPrintReceive[index].Receiver.ReceiverCOD">
              <td style="font-size: 12px;padding-bottom: 5px">ยอดเก็บเงินปลายทาง (COD) {{
                formatPrice(dataPrintReceive[index].Receiver.ReceiverCOD) || '' }} บาท </td>
            </tr>

            <tr>
              <td>

                <table style="border-collapse: collapse;">
                  <tr>
                    <th style="font-size: 12px;width: 90px;border: 1px solid black">รหัสสินค้า</th>
                    <th style="font-size: 12px;width: 250px;border: 1px solid black">ชื่อสินค้า</th>
                    <th style="font-size: 12px;width: 70px;border: 1px solid black">จำนวน</th>
                  </tr>

                  <tr v-for="column in tableDataPrint[index].items" v-bind:key="column.BarCode">
                    <td style="font-size: 12px;width: 90px;border: 1px solid black; text-align: center;">
                      {{ column.BarCode }}
                    </td>
                    <td style="font-size: 12px; padding-left:5px;width: 250px; border: 1px solid black">
                      {{ column.ProductName }}
                    </td>
                    <td style="font-size: 12px;width: 70px;border: 1px solid black; text-align: center;">
                      {{ column.Amount }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>


            <tr v-if="dataPrintReceive[index].Receiver.Ref1">
              <td style="text-align:center;">
                <div style="font-size: 12px;"><br>Ref1</div>
                <VueBarcode v-bind:value="dataPrintReceive[index].Receiver.Ref1" height="30" fontSize="12">
                  rendering fails.
                </VueBarcode>
              </td>
            </tr>
            <tr v-if="dataPrintReceive[index].Receiver.Ref2">
              <td style="text-align:center;">
                <div style="font-size: 12px;">Ref2</div>
                <VueBarcode v-bind:value="dataPrintReceive[index].Receiver.Ref2" height="30" fontSize="12">
                  rendering fails.
                </VueBarcode>
              </td>
            </tr>

            <tr v-if="index != (tableDataSelect.length - 1)">
              <td>
                <div style="page-break-before: always;!important;"></div>
                <div class="page-break"></div>
                <p style="page-break-before: always">&nbsp;</p>
              </td>
            </tr>


          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import moment from 'moment'
import { BaseCheckbox } from 'src/components/index';
import { Modal, BaseAlert } from 'src/components';
import VueBarcode from "vue-barcode";



export default {
  components: {
    Modal,
    BaseAlert,
    VueBarcode,
    BaseCheckbox,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin', 'orderDetail', 'orderHead']),
    queriedData() {
      let result = this.tableData;
      // if (this.searchedData.length > 0) {
      //   result = this.searchedData;
      // }

      result = this.searchedData;
      console.log(this.searchedData.length)

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      checked: false,
      searchQuery: '',
      propsToSearch: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      tableColumns: [
        {
          prop: 'receiver_name',
          label: 'ชื่อผู้รับ',
          minWidth: 200
        },
      ],
      tableData: [],
      tableDataModal: [],
      tableDataModal2: [],
      tableDataSelect: [],
      searchedData: [],
      tableDataPrint: [],
      dataPrintReceive: [],
      dataPrintSender: [],
      Receiver: {
        Name: "",
        Mobile: "",
        Address: "",
        Zipcode: "",
        COD: "",
        SendType: "",
        Ref1: "",
        Ref2: ""
      },
      Sender: {
        CustomerName: "",
        CustomerNo: '',
        CustomerMobile: "",
        CustomerAddress: "",
        CustomerZipcode: "",
        productName: '',
        price: 0,
        amount: 0,
        expireDate: ""
      },
      fuseSearch: null,
      modals: {
        classic: false,
        notice: false,
        mini: false
      },
    };
  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    this.adminPickingOrders()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['adminPickingOrders', 'getOrderDetail', 'deleteOrder2']),
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
      }
    },
    returnSvclinesValue(data) {
      return data;
    },

    formatPrice(value) {
      let val = ((value) / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    onCheckbox(orderNo, index) {
      this.tableDataModal[index].checked = !this.tableDataModal[index].checked
      if (this.tableDataSelect.length === 0) {
        this.tableDataSelect.push(orderNo)

      } else {
        let index = 0;
        let check = false;
        for (let i = 0; i < this.tableDataSelect.length; i++) {
          if (orderNo === this.tableDataSelect[i]) {
            check = true;
            index = i;
          }
        }

        if (check === true) {
          this.tableDataSelect.splice(index, 1);
        } else {
          this.tableDataSelect.push(orderNo)
        }

      }

      this.dataPrintReceive = []
      this.dataPrintSender = []
      this.tableDataPrint = []
      for (let i = 0; i < this.tableDataSelect.length; i++) {
        this.dataPrintReceive.push(
          {
            orderNo: this.tableDataSelect[i],
            Receiver: {
              Name: "",
              Mobile: "",
              Address: "",
              Zipcode: "",
              COD: "",
              SendType: "",
              Ref1: "",
              Ref2: "",
              ReceiverCOD: "",
            },
          }

        )
        this.dataPrintSender.push(
          {
            orderNo: this.tableDataSelect[i],
            Sender: {
              CustomerName: "",
              CustomerNo: '',
              CustomerMobile: "",
              CustomerAddress: "",
              CustomerZipcode: "",
              productName: '',
              price: 0,
              amount: 0,
              expireDate: ""
            },
          })
        this.tableDataPrint.push(
          {
            orderNo: this.tableDataSelect[i],
            items: []
          })

      }

      for (let i = 0; i < this.tableDataSelect.length; i++) {
        this.getOrderDetail(this.tableDataSelect[i])
      }

    },
    CreateProduct() {
      this.$htmlToPaper('sender_info')
    },

    openModal() {
      this.tableDataSelect = []
      // this.tableDataModal = []
      // this.tableDataModal =  this.tableDataModal2
      // this.checked = true
      // this.checked = !this.checked

      for (let i = 0; i < this.tableDataModal.length; i++) {
        if (this.tableDataModal[i].checked === undefined) {
          this.tableDataModal[i].checked = false
        }
      }

      for (let i = 0; i < this.tableDataModal.length; i++) {
        if (this.tableDataModal[i].checked === true) {
          this.tableDataModal[i].checked = !this.tableDataModal[i].checked
        }
      }



      this.modals.notice = true


    },
    handleView(index, row) {
      this.$router.push({ name: 'Order Detail', params: { orderNo: row.orderNo } })

      // let routeData = this.$router.resolve({name: 'Order Detail',params:{orderNo:row.orderNo}});
      // window.open(routeData.href, '_blank');
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });

    },
    deleteRow(row) {
      console.log(row.orderNo)
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.deleteOrder2(row.orderNo)
        this.tableData.splice(indexToDelete, 1);
        window.location.reload()
      }

    },
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['sender_code', 'orderNo', 'receiver_name', 'customer_name', 'date_order'],
      threshold: 0.3
    });
  },
  watch: {
    orderListAdmin(val) {
      this.tableData = val.items.items

      this.tableDataModal = val.items.items
      this.tableDataModal2 = val.items.items
      this.searchQuery = 'OD'

      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }
    },
    orderDetail(val) {
      for (let i = 0; i < this.tableDataPrint.length; i++) {
        if (this.tableDataPrint[i].orderNo != undefined) {
          if (val.items[0].OrderNo != undefined) {
            if (val.items[0].OrderNo === this.tableDataPrint[i].orderNo) {
              this.tableDataPrint[i].items = val.items
            }
          }
        }
      }

    },
    orderHead(val) {
      for (let i = 0; i < this.dataPrintSender.length; i++) {
        if (this.dataPrintSender[i].orderNo != undefined) {
          if (val.item.OrderNo == this.dataPrintSender[i].orderNo) {
            // this.dataPrintSender[i].Sender = this.Sender
            this.dataPrintSender[i].Sender.CustomerName = val.item.SenderName
            this.dataPrintSender[i].Sender.CustomerNo = val.item.Sender
            this.dataPrintSender[i].Sender.CustomerMobile = val.item.SenderTel
            this.dataPrintSender[i].Sender.CustomerZipcode = val.item.SenderZipcode
            this.dataPrintSender[i].Sender.CustomerAddress = val.item.SenderAddress
          }
        }

      }

      for (let i = 0; i < this.dataPrintReceive.length; i++) {
        if (this.dataPrintReceive[i].orderNo != undefined) {
          if (val.item.OrderNo == this.dataPrintReceive[i].orderNo) {
            // this.dataPrintReceive[i].Receiver = this.Receiver
            this.dataPrintReceive[i].Receiver.Ref1 = val.item.Ref1
            this.dataPrintReceive[i].Receiver.Ref2 = val.item.Ref2
            this.dataPrintReceive[i].Receiver.Name = val.item.ReceiverName
            this.dataPrintReceive[i].Receiver.Mobile = val.item.ReceiverTel
            this.dataPrintReceive[i].Receiver.Address = val.item.ReceiverAddress
            this.dataPrintReceive[i].Receiver.Zipcode = val.item.ReceiverZipcode
            this.dataPrintReceive[i].Receiver.COD = val.item.ReceiverCOD
            this.dataPrintReceive[i].Receiver.SendType = val.item.SendType
            this.dataPrintReceive[i].Receiver.ReceiverCOD = val.item.ReceiverCOD
            // this.dataPrintReceive[i].Receiver.ReceiverCOD =  111

          }
        }

      }


    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.0
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      this.tableDataModal = result
      this.tableDataModal2 = result
      this.pagination.currentPage = 1;
    }
  }
};
</script>
